import { Box, Typography } from "@mui/joy";

interface Props {
  name: string;
  currentPeriod: number | undefined;
  previousPeriod?: number;
}

function getGrowthPct(
  current: number | undefined,
  previous: number | undefined
): number | undefined {
  if (current === undefined || previous === undefined) {
    return undefined;
  }
  if (previous === 0) {
    if (current === 0) {
      return 0;
    }
    return Infinity;
  }
  return 100 * (current / previous - 1);
}

export default function AdminMetricDelta(props: Props) {
  const growthPct = getGrowthPct(props.currentPeriod, props.previousPeriod);
  return (
    <Box sx={{ my: 1 }}>
      <Typography fontWeight="bold">{props.name}</Typography>
      <Typography level="h3">{props.currentPeriod}</Typography>
      {growthPct !== undefined && (
        <Typography color={growthPct < 0 ? "danger" : "success"}>
          {growthPct >= 0 && "+"}
          {growthPct === Infinity ? "∞" : growthPct.toFixed(1)}%
        </Typography>
      )}
    </Box>
  );
}
