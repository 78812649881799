import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Textarea,
} from "@mui/joy";
import { Answer, Question } from "./AdminContestTypes";
import CloseIcon from "@mui/icons-material/Close";
import { maxAnswerCount, minAnswerCount } from "./constants";

interface Props {
  question: Question;
  onChange: (question: Question) => void;
  disabled: boolean;
}

function checkText(text: string) {
  if (text === "") {
    return "Can't be empty";
  }
}

function checkAnswer(answer: Answer) {
  return checkText(answer.text);
}

function checkAnswers(answers: Answer[]) {
  if (answers.length < minAnswerCount) {
    return "Too few answers";
  }
  if (answers.length > maxAnswerCount) {
    return "Too many answers";
  }
  const correctAnswers = answers.filter((answer) => answer.correct);
  if (correctAnswers.length !== 1) {
    return "Exactly one answer must be correct";
  }
  if (!answers.every((answer) => checkAnswer(answer) === undefined)) {
    return "One or more answers have errors";
  }
}

export function isValidQuestion(question: Question) {
  return (
    checkText(question.text) === undefined &&
    checkAnswers(question.answers) === undefined
  );
}

export default function AdminContestQuestionInput(props: Props) {
  const setText = (text: string) => props.onChange({ ...props.question, text });
  const setAnswers = (answers: Answer[]) =>
    props.onChange({ ...props.question, answers });
  const textError = checkText(props.question.text);
  const answersError = checkAnswers(props.question.answers);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl sx={{ mt: 1 }}>
        <Textarea
          value={props.question.text}
          onChange={(event) => setText(event.target.value)}
          disabled={props.disabled}
          error={textError !== undefined}
        />
        {textError && (
          <FormHelperText
            sx={(theme) => ({
              color: theme.vars.palette.danger[500],
            })}
          >
            {textError}
          </FormHelperText>
        )}
      </FormControl>
      {props.question.answers.map((answer, index) => {
        const textError = checkText(answer.text);
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
            }}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <Textarea
                value={answer.text}
                onChange={(event) =>
                  setAnswers(
                    Object.assign([], props.question.answers, {
                      [index]: { ...answer, text: event.target.value },
                    })
                  )
                }
                disabled={props.disabled}
                error={textError !== undefined}
              />
              {textError && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[500],
                  })}
                >
                  {textError}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <Checkbox
                label="Correct"
                checked={answer.correct}
                onChange={(event) => {
                  const checked = event.target.checked;
                  setAnswers(
                    Object.assign([], props.question.answers, {
                      [index]: { ...answer, correct: event.target.checked },
                    })
                  );
                }}
                uncheckedIcon={<CloseIcon />}
                disabled={props.disabled}
              />
            </FormControl>
          </Box>
        );
      })}
      <FormControl>
        {answersError && (
          <FormHelperText
            sx={(theme) => ({
              color: theme.vars.palette.danger[500],
            })}
          >
            {answersError}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
