"use strict";

import React from "react";
import GameLink from "./GameLink";
import GamePrepareGuest from "./GamePrepareGuest";
import GamePrepareHost from "./GamePrepareHost";

import useSession from "./useSession";
import { Box } from "@mui/material";
import { ClassicState } from "./ClassicState";

interface Props {
  game: ClassicState;
}

export default function GamePrepare(props: Props) {
  const session = useSession();
  const host = React.useMemo(() => {
    const player = props.game.players.find(
      (player) => player.userId === session.user?.userId
    );
    return player !== undefined && player.host;
  }, [session.user?.userId, props.game.players]);
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <GameLink />
      {host ? <GamePrepareHost /> : <GamePrepareGuest />}
    </Box>
  );
}
