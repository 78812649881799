import React from "react";

import GameCard from "./GameCard";
import { Game } from "./types";
import useColyseus from "./useColyseus";
import { Card, Grid, Typography } from "@mui/material";
import background from "./escheresque_ste.png";

const refreshEveryMs = 3000;

const GameList = () => {
  const client = useColyseus();
  const [games, setGames] = React.useState<Game[]>([]);
  React.useEffect(() => {
    let subscribed = true;
    const refresh = async () => {
      if (!subscribed) {
        return;
      }
      try {
        const rooms = await client.getAvailableRooms("classic");
        const games = rooms.map((room) => ({
          gameId: room.roomId,
          title: room.metadata?.title || "",
          categories: room.metadata?.categories || [],
          userCount: room.metadata?.userCount || 0,
          maxUserCount: room.metadata?.maxUserCount || 0,
          paused: room.metadata?.paused || false,
          questionCount: room.metadata?.questionCount || 0,
          answerTime: room.metadata?.answerTime || 0,
          createdAt: room.metadata?.createdAt || 0,
        }));
        games.sort((x, y) => {
          if (x.userCount < x.maxUserCount !== y.userCount < y.maxUserCount) {
            return x.userCount < x.maxUserCount ? -1 : 1;
          }
          if (x.userCount !== y.userCount) {
            return x.userCount < y.userCount ? -1 : 1;
          }
          if (x.createdAt !== y.createdAt) {
            return x.createdAt > y.createdAt ? -1 : 1;
          }
          return 0;
        });
        setGames(games);
      } catch (error) {
        console.error(error);
      }
      if (subscribed) {
        setTimeout(refresh, refreshEveryMs);
      }
    };
    refresh();
    return () => {
      subscribed = false;
    };
  }, [client]);
  return (
    <Card sx={{ backgroundImage: `url(${background})` }}>
      <Typography variant="h5" sx={{ color: "white" }} align="center">
        Lista de partidas
      </Typography>
      {games.length === 0 ? (
        <Typography sx={{ color: "white", py: 6 }} align="center">
          No hay ninguna partida pública que mostrar. ¡Empieza una nueva!
        </Typography>
      ) : (
        <Grid container sx={{ p: 1 }} spacing={1}>
          {games.map((game) => (
            <GameCard key={game.gameId} game={game} />
          ))}
        </Grid>
      )}
    </Card>
  );
};

export default GameList;
