import { Box, Button, Card, Grid, Typography } from "@mui/material";
import useAnalytics from "./useAnalytics";
import useClassicRoom from "./useClassicRoom";
import { Replay } from "@mui/icons-material";

export default function GameIntermissionGuest() {
  const room = useClassicRoom();
  const analytics = useAnalytics();
  return (
    <Box sx={{ p: 0.5, flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Card sx={{ backgroundColor: "#e3dcd4", py: 5, px: 1, flexGrow: 1 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Fin de la partida
        </Typography>
        <Grid container direction="column" spacing={2} sx={{ py: 3 }}>
          <Grid item container direction="row" justifyContent="center">
            <Grid item xs={12} sm={7} lg={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  room.send("rematch");
                  analytics.event("rematch_requested");
                }}
                startIcon={<Replay />}
                color="success"
              >
                ¡Revancha!
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Typography gutterBottom>
          El botón de revancha sirve para indicar a los demás que quieres jugar
          otra partida, pero la decisión queda en manos del anfitrión.
        </Typography>
      </Card>
    </Box>
  );
}
