import { extendTheme } from "@mui/joy/styles";

export default extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: "var(--joy-palette-neutral-50)",
        },
      },
    },
  },
});
