import { Box } from "@mui/material";
import ScoreboardItemStatus from "./ScoreboardItemStatus";
import useSession from "./useSession";
import {
  Star as StarIcon,
  Person as PersonIcon,
  Login as LoginIcon,
} from "@mui/icons-material";

interface Props {
  userId: string;
  position: number;
  displayName: string;
  host: boolean;
  joinedLate: boolean;
  status: string;
  points: number;
  correctAnswers: number;
}

export default function ScoreboardItem(props: Props) {
  const session = useSession();
  const self = session.user?.userId === props.userId;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ ml: 0.5 }}>
        <Box>
          <Box
            sx={{
              borderRadius: 1,
              backgroundColor: "black",
              color: "white",
              display: "inline",
              fontSize: 14,
              px: 0.5,
              py: 0.1,
              fontWeight: "bold",
            }}
          >
            {props.position}
          </Box>{" "}
          {props.displayName}
          {props.host ? " " : null}
          {props.host ? (
            <StarIcon sx={{ fontSize: 22 }} titleAccess="Anfitrión" />
          ) : null}
          {props.joinedLate ? " " : null}
          {props.joinedLate ? (
            <LoginIcon
              sx={{ fontSize: 22 }}
              titleAccess="Entró a media partida"
            />
          ) : null}
          {self ? " " : null}
          {self ? <PersonIcon sx={{ fontSize: 22 }} titleAccess="Tú" /> : null}
        </Box>
        <Box>
          <ScoreboardItemStatus status={props.status} />
        </Box>
      </Box>
      <Box sx={{ mr: 0.5 }}>
        <div>{props.points} puntos</div>
        <div>{props.correctAnswers} aciertos</div>
      </Box>
    </Box>
  );
}
