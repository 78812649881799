import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

interface Props {
  value: boolean;
}

export default function EnhancedTableBooleanCell(props: Props) {
  return props.value ? <DoneIcon /> : <CloseIcon />;
}
