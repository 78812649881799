import { Box, Card, Typography } from "@mui/joy";
import AdminLayout from "./AdminLayout";
import AdminQuestionCreateForm from "./AdminQuestionCreateForm";

export default function AdminQuestionCreatePage() {
  return (
    <AdminLayout>
      <Box sx={{ my: 2 }}>
        <Card>
          <Typography level="h6" gutterBottom>
            New question
          </Typography>
          <AdminQuestionCreateForm />
        </Card>
      </Box>
    </AdminLayout>
  );
}
