import React from "react";
import * as Colyseus from "colyseus.js";
import { Schema } from "@colyseus/schema";
import { Mutex } from "async-mutex";

import { RoomStatus } from "./createRoomContext";
import { EventEmitter } from "events";
import useColyseus from "./useColyseus";

export default function useRoomProvider<State extends Schema>(
  roomId: string,
  options: any
) {
  const client = useColyseus();
  const { current: mutex } = React.useRef(new Mutex());
  const { current: emitter } = React.useRef(new EventEmitter());
  const [status, setStatus] = React.useState<RoomStatus>(
    RoomStatus.disconnected
  );
  const [connectedRoomId, setConnectedRoomId] = React.useState<null | string>(
    null
  );
  const [sessionId, setSessionId] = React.useState<null | string>(null);
  const [state, setState] = React.useState<State | null>(null);
  const [send, setSend] = React.useState<(type: string, message?: any) => void>(
    () => () => {}
  );
  const [leave, setLeave] = React.useState(() => () => {});
  const [timeDiff, setTimeDiff] = React.useState(0);

  React.useEffect(() => {
    let room: null | Colyseus.Room<State> = null;
    let subscribed = true;

    const cleanup = (consented: boolean) => {
      if (!subscribed) {
        return;
      }
      subscribed = false;
      if (room) {
        const thisRoom = room;
        mutex.runExclusive(() => thisRoom.leave(consented));
      }
      setStatus(RoomStatus.disconnected);
      setConnectedRoomId(null);
      setSessionId(null);
      setState(null);
      setSend(() => () => {});
    };

    const connect = async () => {
      await mutex.runExclusive(async () => {
        if (!subscribed) {
          return;
        }
        setStatus(RoomStatus.connecting);
        const sessionId = sessionStorage.getItem(roomId);
        const makeRoom = sessionId
          ? () => client.reconnect<State>(roomId, sessionId)
          : () => client.joinById<State>(roomId, options);
        try {
          room = await makeRoom();
        } catch (error) {
          if (!subscribed) {
            return;
          }
          if (error instanceof Error) {
            if (error.message === `session expired: ${sessionId}`) {
              sessionStorage.removeItem(roomId);
              setTimeout(connect, 0);
              return;
            }
            if (error.message === `room "${roomId}" not found`) {
              setStatus(RoomStatus.notFound);
              return;
            }
          }
          console.error(error);
          setStatus(RoomStatus.error);
          setTimeout(connect, 1000);
          return;
        }
        if (!subscribed) {
          await room.leave(false);
          return;
        }
        setStatus(RoomStatus.connected);
        setConnectedRoomId(room.id);
        setSessionId(room.sessionId);
        const thisRoom = room;
        setSend(
          () => (type: string, message?: any) => thisRoom.send(type, message)
        );
        setLeave(() => () => cleanup(true));
        room.onStateChange((state) => {
          setState(state.clone());
        });
        room.send("ping", Date.now());
        room.onMessage("pong", (message) => {
          const { clientTime, serverTime } = message;
          if (
            typeof clientTime !== "number" ||
            typeof serverTime !== "number"
          ) {
            return;
          }
          const now = Date.now();
          const latency = now - clientTime;
          const expectedServerTime = clientTime + 0.5 * latency;
          const timeDiff = expectedServerTime - serverTime;
          setTimeDiff(timeDiff);
        });
        room.onMessage("*", (type, message) => {
          emitter.emit(type.toString(), message);
        });
        room.onError(console.error);
        room.onLeave((code) => {
          thisRoom.removeAllListeners();
          if (!subscribed) {
            return;
          }
          setStatus(RoomStatus.error);
          setTimeout(connect, 1000);
        });
        sessionStorage.setItem(room.id, room.sessionId);
      });
    };

    connect();

    return () => cleanup(false);
  }, [client, mutex, roomId, options, emitter]);

  const on = React.useMemo(() => {
    return (type: string, listener: (message: any) => void) => {
      emitter.on(type, listener);
    };
  }, [emitter]);

  const off = React.useMemo(() => {
    return (type: string, listener: (message: any) => void) => {
      emitter.off(type, listener);
    };
  }, [emitter]);

  const context = React.useMemo(
    () => ({
      status,
      roomId: connectedRoomId,
      sessionId,
      state,
      send,
      on,
      off,
      leave,
      timeDiff,
    }),
    [status, connectedRoomId, sessionId, state, send, on, off, leave]
  );

  return context;
}
