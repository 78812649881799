import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useApi from "./useApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useSnackbar from "./useSnackbar";
import { passwordMaxLength } from "./constants";
import { LoadingButton } from "@mui/lab";
import { Checkbox, DialogContentText, FormControlLabel } from "@mui/material";
import useSession from "./useSession";
import useAnalytics from "./useAnalytics";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function DeleteAccountModal(props: Props) {
  const session = useSession();
  const [password, setPassword] = React.useState("");
  const api = useApi();
  const snackbar = useSnackbar();
  const [confirmed, setConfirmed] = React.useState(false);
  const analytics = useAnalytics();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Eliminar cuenta</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Eliminar tu cuenta es una operación{" "}
          <strong>permanente e irreversible</strong>. Una vez eliminada, no la
          podrás recuperar de ninguna forma. Introduce tu contraseña actual para
          confirmar que realmente quieres eliminar tu cuenta.
        </DialogContentText>
        <TextField
          label="Contraseña"
          autoFocus
          margin="normal"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          autoComplete="password"
          onChange={(event) => setPassword(event.target.value)}
          inputProps={{
            maxLength: passwordMaxLength,
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={confirmed}
              onChange={(_, checked) => {
                setConfirmed(checked);
              }}
            />
          }
          label="Quiero eliminar mi cuenta de forma permanente e irreversible."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancelar</Button>
        <LoadingButton
          variant="contained"
          disabled={password.length === 0 || !confirmed}
          loading={api.loading}
          color="error"
          onClick={
            api.loading
              ? undefined
              : () =>
                  api.post({
                    path: "/settings/delete-account",
                    params: { password },
                    onSuccess: () => {
                      session.refresh();
                      props.onClose();
                      snackbar.success("Has eliminado tu cuenta");
                      analytics.event("account_deleted");
                    },
                    onError: (error) => {
                      if (error === "wrong password") {
                        snackbar.error("Contraseña incorrecta");
                        analytics.event("delete_account_wrong_password");
                      } else {
                        analytics.event("delete_account_error");
                      }
                    },
                  })
          }
        >
          Eliminar cuenta
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
