import nullthrows from "nullthrows";
import React from "react";
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import useClassicRoom from "./useClassicRoom";
import { Box, Card, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ChatOverlay from "./ChatOverlay";

export default function Chat() {
  const room = useClassicRoom();
  const [sticky, setSticky] = React.useState(true);
  const state = nullthrows(room.state);
  const displayNames = React.useMemo(() => {
    const displayNames: { [userId: string]: string } = {};
    state.players.forEach((player) => {
      displayNames[player.userId] = player.displayName;
    });
    return displayNames;
  }, [state.players]);
  const messages = React.useMemo(
    () =>
      state.messages.map((message) => ({
        messageIndex: message.messageIndex,
        messageText: message.messageText,
        userId: message.userId,
        displayName: displayNames[message.userId] || "",
      })),
    [displayNames, state.messages]
  );

  const messagesRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const elem = messagesRef.current;
    if (elem === null) {
      return;
    }
    if (sticky) {
      elem.scrollTo({ top: elem.scrollHeight });
    }
  }, [state.messages]);

  return (
    <Card sx={{ position: "relative", backgroundColor: "#e3dcd4" }}>
      <Box
        ref={messagesRef}
        onScroll={(event) => {
          const elem = event.target as HTMLDivElement;
          setSticky(elem.scrollTop + elem.clientHeight >= elem.scrollHeight);
        }}
        sx={{
          height: "250px",
          overflowX: "hidden",
          overflowY: "auto",
          pt: 0.5,
        }}
      >
        {messages.map((message) => (
          <ChatMessage
            key={message.messageIndex}
            userId={message.userId}
            displayName={message.displayName}
            messageText={message.messageText}
          />
        ))}
      </Box>
      <ChatInput />
      <ChatOverlay />
    </Card>
  );
}
