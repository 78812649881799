import React from "react";
import NameChangeModal from "./NameChangeModal";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";

interface Props {
  onComplete: () => void;
}

export default function NameChangeDropdownItem(props: Props) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(true);
          props.onComplete();
        }}
      >
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Cambiar nombre</ListItemText>
      </MenuItem>
      {open && <NameChangeModal open={open} onClose={() => setOpen(false)} />}
    </>
  );
}
