import { Box } from "@mui/joy";
import AdminLayout from "./AdminLayout";
import AdminQuestionsTable from "./AdminQuestionsTable";

export default function AdminQuestionsPage() {
  return (
    <AdminLayout>
      <Box sx={{ my: 2 }}>
        <AdminQuestionsTable />
      </Box>
    </AdminLayout>
  );
}
