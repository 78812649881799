import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useSession from "./useSession";
import useApi from "./useApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useSnackbar from "./useSnackbar";
import { displayNameMaxLength, displayNameMinLength } from "./constants";
import { useBus } from "react-bus";
import { LoadingButton } from "@mui/lab";
import useAnalytics from "./useAnalytics";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function NameChangeModal(props: Props) {
  const session = useSession();
  const [name, setName] = React.useState("");
  const api = useApi();
  const snackbar = useSnackbar();
  const bus = useBus();
  const analytics = useAnalytics();

  React.useEffect(() => {
    if (props.open) {
      setName(session.user?.displayName ?? "");
    }
  }, [props.open, session.user?.displayName]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Cambiar nombre</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Elige el nombre que verán los demás jugadores.
        </DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          type="text"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(event: any) => setName(event.target.value)}
          inputProps={{
            minLength: displayNameMinLength,
            maxLength: displayNameMaxLength,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancelar</Button>
        <LoadingButton
          variant="contained"
          disabled={name.length === 0 || name === session.user?.displayName}
          loading={api.loading}
          onClick={
            api.loading
              ? undefined
              : () =>
                  api.post({
                    path: "/settings/display-name",
                    params: { displayName: name },
                    onSuccess: () => {
                      session.refresh();
                      props.onClose();
                      snackbar.success("Nombre actualizado");
                      bus.emit("nameChanged", name);
                      analytics.event("name_changed");
                    },
                    onError: () => {
                      analytics.event("name_change_error");
                    },
                  })
          }
        >
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
