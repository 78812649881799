import { Box } from "@mui/joy";
import AdminLayout from "./AdminLayout";
import AdminUsersTable from "./AdminUsersTable";

export default function AdminUsersPage() {
  return (
    <AdminLayout>
      <Box sx={{ my: 2 }}>
        <AdminUsersTable />
      </Box>
    </AdminLayout>
  );
}
