import React from "react";
import Page from "./Page";
import email from "./email.png";
import { Card, Link, Typography } from "@mui/material";

function ContactPage() {
  return (
    <Page title="Contacta con nosotros">
      <Card sx={{ p: 3 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Contacta con nosotros
        </Typography>
        <Typography gutterBottom>
          ¿Has tenido algún problema? ¿Tienes sugerencias?
        </Typography>
        <Typography gutterBottom>
          Envíanos tus quejas, sugerencias y comentarios a través de{" "}
          <Link
            href="https://www.facebook.com/TrivialOnlinees-423873727655602/"
            target="_blank"
          >
            Facebook
          </Link>{" "}
          o a través de nuestra dirección de correo electrónico:{" "}
          <Link href="mailto:contacto@trivialonline.es" target="_blank">
            contacto@trivialonline.es
          </Link>
        </Typography>
      </Card>
    </Page>
  );
}

export default ContactPage;
