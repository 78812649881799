import React from "react";
import useSession from "./useSession";
import { Navigate } from "react-router-dom";
import Page from "./Page";

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function SignedInPage(props: Props) {
  const session = useSession();
  const signedIn = session.user?.isGuest === false;
  if (!signedIn) {
    return <Navigate to="/" />;
  }
  return <Page title={props.title}>{props.children}</Page>;
}
