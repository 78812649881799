import nullthrows from "nullthrows";
import {
  ClassicState,
  Question as QuestionSchema,
  Answer as AnswerSchema,
} from "./ClassicState";
import Answer from "./Answer";
import QuestionReport from "./QuestionReport";
import useAnalytics from "./useAnalytics";

import useClassicRoom from "./useClassicRoom";
import useSession from "./useSession";
import { Card, Typography } from "@mui/material";

function getStatus(state: ClassicState, answer: AnswerSchema, userId: string) {
  const selectedAnswerId = state.selectedAnswerId.get(userId);
  if (state.status === "questionInProgress") {
    if (selectedAnswerId === answer.answerId) {
      return "selected";
    }
    return "none";
  }
  if (state.status === "questionEnd") {
    if (answer.status === "correct") {
      return "correct";
    }
    if (selectedAnswerId === undefined) {
      return "incorrect";
    }
    if (selectedAnswerId === answer.answerId) {
      return "incorrect";
    }
    return "none";
  }
  return "none";
}

interface Props {
  question: QuestionSchema;
  inProgress: boolean;
}

export default function Question(props: Props) {
  const room = useClassicRoom();
  const session = useSession();
  const analytics = useAnalytics();
  return (
    <Card sx={{ backgroundColor: "#e3dcd4", p: 0.5, flexGrow: 1 }}>
      <Typography sx={{ fontSize: 20, mb: 2 }}>
        {props.question.questionText}
      </Typography>
      {props.question.answers.map((answer) => (
        <Answer
          key={answer.answerId}
          status={getStatus(
            nullthrows(room.state),
            answer,
            nullthrows(session.user?.userId)
          )}
          text={answer.answerText}
          onSelect={
            props.inProgress
              ? () => {
                  room.send("answer", answer.answerId);
                  analytics.event("question_answered");
                }
              : undefined
          }
        />
      ))}
      <QuestionReport questionId={props.question.questionId} />
    </Card>
  );
}
