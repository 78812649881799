import * as React from "react";

import useRoomProvider from "./useRoomProvider";
import ClassicRoomContext from "./ClassicRoomContext";
import { useBus } from "react-bus";
import useSession from "./useSession";
import { ClassicState } from "./ClassicState";

interface Props {
  roomId: string;
  options?: any;
  children: React.ReactNode;
}

export default function ClassicRoomProvider(props: Props) {
  const roomContext = useRoomProvider<ClassicState>(
    props.roomId,
    props.options
  );
  const bus = useBus();
  const session = useSession();

  // Notify name changes.
  React.useEffect(() => {
    const notify = (name: any) => {
      roomContext.send("changeName", name);
    };
    bus.on("nameChanged", notify);
    return () => bus.off("nameChanged", notify);
  }, [roomContext, bus]);

  return (
    <ClassicRoomContext.Provider value={roomContext}>
      {props.children}
    </ClassicRoomContext.Provider>
  );
}
