import { Button, ListItem, ListItemText } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import React from "react";
import DeleteAccountModal from "./DeleteAccountModal";

export default function SettingsDeleteAccountListItem() {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <ListItem
        secondaryAction={
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            onClick={() => setOpen(true)}
          >
            Eliminar cuenta
          </Button>
        }
      >
        <ListItemText
          primary="Eliminar cuenta"
          secondary={"Eliminar tu cuenta de forma permanente"}
        />
      </ListItem>
      {open && (
        <DeleteAccountModal open={open} onClose={() => setOpen(false)} />
      )}
    </>
  );
}
