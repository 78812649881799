import React from "react";

export default function usePreviousFallback<T>(
  value: T | undefined
): T | undefined {
  const fallback = React.useRef<T | undefined>(undefined);
  const cached = React.useMemo(() => {
    if (value !== undefined) {
      fallback.current = value;
    }
    return fallback.current;
  }, [value]);
  return cached;
}
