export const displayNameMaxLength = 50;
export const displayNameMinLength = 1;
export const usernameMaxLength = 32;
export const usernameMinLength = 3;
export const passwordMinLength = 4;
export const passwordMaxLength = 64;
export const minAnswerCount = 4;
export const maxAnswerCount = 4;
export const titleMinLength = 1;
export const titleMaxLength = 64;
export const minQuestionCount = 5;
export const maxQuestionCount = 100;
export const minAnswerTime = 5;
export const maxAnswerTime = 45;
export const messageMinLength = 1;
export const messageMaxLength = 200;
export const roomReconnectionTime = 3;
export const questionReportExpireAfterSeconds = 365 * 24 * 60 * 60; // 1 year
export const messageRetentionCount = 100;
export const askedQuestionRetentionCount = 1000;
export const usernameCollation = { locale: "en", strength: 2 }; // case insensitive
export const otpTokenStep = 30;
export const otpTokenWindow = 10;
export const otpTokenRegex = /^[0-9]+$/;
export const verifyEmailOtpTokenLength = 6;
export const recoverPasswordOtpTokenLength = 12;
export const verifyEmailRateLimitWindowMs = 60 * 60 * 1000; // 1 hour
export const verifyEmailRateLimitMaxEvents = 10;
export const recoverPasswordRateLimitWindowMs = 60 * 60 * 1000; // 1 hour
export const recoverPasswordRateLimitMaxEvents = 10;
export const signinRateLimitWindowMs = 60 * 1000; // 1 minute
export const signinRateLimitMaxEvents = 10;
export const maxQueryLimit = 100;
