import React from "react";
import { Box, Button } from "@mui/joy";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import { useNavigate } from "react-router-dom";
import { Contest } from "./AdminContestTypes";
import AdminContestInput, { isValidContest } from "./AdminContestInput";

export default function AdminContestCreateForm() {
  const [contest, setContest] = React.useState<Contest>({
    _id: undefined,
    title: "",
    questions: [
      {
        text: "",
        answers: [0, 1, 2, 3].map(() => ({ text: "", correct: false })),
      },
    ],
    isPublic: false,
    startsAt: undefined,
    createdAt: undefined,
    updatedAt: undefined,
  });
  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const valid = isValidContest(contest);
  const submit = () => {
    if (api.loading) {
      return;
    }
    api.post({
      path: `/admin/contests`,
      params: { contest },
      onSuccess: (response) => {
        snackbar.success("Contest created");
        navigate(`/admin/contests/${response.contest._id}`);
      },
    });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <AdminContestInput
        contest={contest}
        onChange={setContest}
        disabled={api.loading}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          gap: 2,
        }}
      >
        <Button variant="plain" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button disabled={!valid} loading={api.loading} onClick={submit}>
          Create
        </Button>
      </Box>
    </Box>
  );
}
