import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Sheet,
  Textarea,
} from "@mui/joy";
import { Contest, Question } from "./AdminContestTypes";
import AdminContestQuestionInput, {
  isValidQuestion,
} from "./AdminContestQuestionInput";
import { DateTimeField } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import nullthrows from "nullthrows";

interface Props {
  contest: Contest;
  onChange: (question: Contest) => void;
  disabled: boolean;
}

function checkTitle(title: string) {
  if (title === "") {
    return "Can't be empty";
  }
}

function checkQuestions(questions: Question[]) {
  if (questions.length === 0) {
    return "There must be at least one question";
  }
  if (!questions.every(isValidQuestion)) {
    return "One or more questions have errors";
  }
}

function checkStartsAt(startsAt: string | undefined) {
  if (startsAt === undefined) {
    return "Start time must be set";
  }
  if (isNaN(Date.parse(startsAt))) {
    return "Invalid date";
  }
}

export function isValidContest(contest: Contest) {
  return (
    checkTitle(contest.title) === undefined &&
    checkQuestions(contest.questions) === undefined &&
    checkStartsAt(contest.startsAt) === undefined
  );
}

export default function AdminContestInput(props: Props) {
  const setTitle = (title: string) =>
    props.onChange({ ...props.contest, title });
  const setQuestions = (questions: Question[]) =>
    props.onChange({ ...props.contest, questions });
  const setIsPublic = (isPublic: boolean) =>
    props.onChange({ ...props.contest, isPublic });
  const setStartsAt = (startsAt: Dayjs | null) => {
    console.log(startsAt);
    props.onChange({
      ...props.contest,
      startsAt:
        startsAt === null
          ? undefined
          : startsAt.second(0).millisecond(0).toISOString(),
    });
  };
  const titleError = checkTitle(props.contest.title);
  const questionsError = checkQuestions(props.contest.questions);
  const startsAtError = checkStartsAt(props.contest.startsAt);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <Textarea
          value={props.contest.title}
          onChange={(event) => setTitle(event.target.value)}
          disabled={props.disabled}
          error={titleError !== undefined}
        />
        {titleError && (
          <FormHelperText
            sx={(theme) => ({
              color: theme.vars.palette.danger[500],
            })}
          >
            {titleError}
          </FormHelperText>
        )}
      </FormControl>
      {props.contest.questions.map((question, index) => {
        return (
          <Sheet variant="outlined" sx={{ p: 1, borderRadius: 6 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography gutterBottom>Question {index + 1} </Typography>
              </Box>
              <IconButton
                size="sm"
                color="neutral"
                title="Move down"
                disabled={
                  props.disabled || index === props.contest.questions.length - 1
                }
                onClick={() =>
                  setQuestions([
                    ...props.contest.questions.slice(0, index),
                    nullthrows(props.contest.questions.at(index + 1)),
                    nullthrows(props.contest.questions.at(index)),
                    ...props.contest.questions.slice(index + 2),
                  ])
                }
              >
                <ArrowDownwardIcon />
              </IconButton>
              <IconButton
                size="sm"
                color="neutral"
                title="Move up"
                disabled={props.disabled || index === 0}
                onClick={() =>
                  setQuestions([
                    ...props.contest.questions.slice(0, index - 1),
                    nullthrows(props.contest.questions.at(index)),
                    nullthrows(props.contest.questions.at(index - 1)),
                    ...props.contest.questions.slice(index + 1),
                  ])
                }
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                size="sm"
                color="danger"
                onClick={() =>
                  setQuestions([
                    ...props.contest.questions.slice(0, index),
                    ...props.contest.questions.slice(index + 1),
                  ])
                }
                disabled={
                  props.disabled || props.contest.questions.length === 1
                }
                title="Delete"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            <AdminContestQuestionInput
              key={index}
              question={question}
              onChange={(question) => {
                setQuestions(
                  Object.assign([], props.contest.questions, {
                    [index]: question,
                  })
                );
              }}
              disabled={props.disabled}
            />
          </Sheet>
        );
      })}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          startDecorator={<AddIcon />}
          color="success"
          size="sm"
          disabled={props.disabled}
          onClick={() =>
            setQuestions([
              ...props.contest.questions,
              {
                text: "",
                answers: [0, 1, 2, 3].map((_) => ({
                  text: "",
                  correct: false,
                })),
              },
            ])
          }
        >
          Add question
        </Button>
      </Box>
      <FormControl>
        {questionsError && (
          <FormHelperText
            sx={(theme) => ({
              color: theme.vars.palette.danger[500],
            })}
          >
            {questionsError}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <FormLabel>Public</FormLabel>
        <Checkbox
          checked={props.contest.isPublic}
          onChange={(event) => setIsPublic(event.target.checked)}
          disabled={props.disabled}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Starts at</FormLabel>
        <DateTimeField
          value={
            props.contest.startsAt === undefined
              ? null
              : dayjs(props.contest.startsAt)
          }
          onChange={setStartsAt}
          disabled={props.disabled}
        />
        {startsAtError && (
          <FormHelperText
            sx={(theme) => ({
              color: theme.vars.palette.danger[500],
            })}
          >
            {startsAtError}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
