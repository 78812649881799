import nullthrows from "nullthrows";
import React from "react";
import SettingsWidget from "./SettingsWidget";
import useClassicRoom from "./useClassicRoom";
import { Settings, Visibility } from "./types";
import useAnalytics from "./useAnalytics";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { Build as BuildIcon } from "@mui/icons-material";

function validateVisibility(visibility: string): Visibility {
  if (visibility === "public" || visibility === "private") {
    return visibility;
  }
  throw new Error("invalid visibility");
}

export default function GamePrepareHost() {
  const room = useClassicRoom();
  const [settingsMode, setSettingsMode] = React.useState(false);
  const initialSettings = React.useMemo<Settings>(() => {
    const state = nullthrows(room.state);
    return {
      title: state.title,
      visibility: validateVisibility(state.visibility),
      questionCount: state.questionCount,
      answerTime: state.answerTime,
      categories: state.categories.toArray(),
    };
  }, [room.state]);
  const analytics = useAnalytics();

  if (settingsMode) {
    return (
      <SettingsWidget
        initialSettings={initialSettings}
        onSave={(settings) => {
          room.send("settings", settings);
          analytics.event("settings_updated");
          setSettingsMode(false);
        }}
        onCancel={() => {
          setSettingsMode(false);
        }}
        submitButtonText="Guardar cambios"
      />
    );
  }

  return (
    <Box sx={{ p: 0.5, flexGrow: 1 }}>
      <Card
        sx={{
          backgroundColor: "#e3dcd4",
          py: 5,
          px: 1,
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4">Inicia la partida cuando quieras</Typography>
        <Grid container direction="column" spacing={2} sx={{ py: 3 }}>
          <Grid item container direction="row" justifyContent="center">
            <Grid item xs={12} sm={7} lg={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  room.send("start");
                  analytics.event("game_started");
                }}
              >
                ¡Empezar partida!
              </Button>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="center">
            <Grid item xs={12} sm={7} lg={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  setSettingsMode(true);
                }}
                startIcon={<BuildIcon />}
                color="inherit"
              >
                Ajustes
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Typography gutterBottom>
          Si estás jugando con más jugadores, no les hagas esperar, o se
          aburrirán y se irán.
        </Typography>
        <Typography gutterBottom>
          Pueden seguir entrando jugadores una vez haya empezado la partida.
        </Typography>
      </Card>
    </Box>
  );
}
