import React from "react";
import { Question } from "./AdminQuestionTypes";
import { Box, Button } from "@mui/joy";
import AdminQuestionInput, { isValidQuestion } from "./AdminQuestionInput";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import { useNavigate } from "react-router-dom";

interface Props {
  question: Question;
}

export default function AdminQuestionEditForm(props: Props) {
  const [question, setQuestion] = React.useState(props.question);
  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const valid = isValidQuestion(question);
  const submit = () => {
    if (api.loading) {
      return;
    }
    api.patch({
      path: `/admin/questions/${question._id}`,
      params: { question },
      onSuccess: () => snackbar.success("Question updated"),
    });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <AdminQuestionInput
        question={question}
        onChange={setQuestion}
        disabled={api.loading}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          gap: 2,
        }}
      >
        <Button variant="plain" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button disabled={!valid} loading={api.loading} onClick={submit}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
