import Typography from "@mui/joy/Typography";
import { Grid, Sheet } from "@mui/joy";
import useGetApi, { Result } from "./useGetApi";
import AdminMetricDelta from "./AdminMetricDelta";

function getCount(result: Result): number | undefined {
  if (result.status !== "success") {
    return undefined;
  }
  const count = result.content.count;
  if (typeof count !== "number") {
    return undefined;
  }
  return count;
}

export default function AdminVerifiedSignups() {
  const total = useGetApi("/admin/metrics/verified-signups-total");
  const currentDay = useGetApi("/admin/metrics/verified-signups-current-day");
  const previousDay = useGetApi("/admin/metrics/verified-signups-previous-day");
  const currentWeek = useGetApi("/admin/metrics/verified-signups-current-week");
  const previousWeek = useGetApi(
    "/admin/metrics/verified-signups-previous-week"
  );
  const currentMonth = useGetApi(
    "/admin/metrics/verified-signups-current-month"
  );
  const previousMonth = useGetApi(
    "/admin/metrics/verified-signups-previous-month"
  );
  return (
    <Sheet variant="outlined" sx={{ p: 2, my: 2 }}>
      <Typography level="h5" gutterBottom>
        Verified Signups
      </Typography>
      <Grid container>
        <Grid xs={6} sm={3}>
          <AdminMetricDelta name="Total" currentPeriod={getCount(total)} />
        </Grid>
        <Grid xs={6} sm={3}>
          <AdminMetricDelta
            name="Last 24 hours"
            currentPeriod={getCount(currentDay)}
            previousPeriod={getCount(previousDay)}
          />
        </Grid>
        <Grid xs={6} sm={3}>
          <AdminMetricDelta
            name="Last 7 days"
            currentPeriod={getCount(currentWeek)}
            previousPeriod={getCount(previousWeek)}
          />
        </Grid>
        <Grid xs={6} sm={3}>
          <AdminMetricDelta
            name="Last 30 days"
            currentPeriod={getCount(currentMonth)}
            previousPeriod={getCount(previousMonth)}
          />
        </Grid>
      </Grid>
    </Sheet>
  );
}
