import { Box, Card, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useSession from "./useSession";

export default function ChatOverlay() {
  const session = useSession();
  if (session.user?.emailVerified === true) {
    return null;
  }
  return (
    <Card
      sx={{
        position: "absolute",
        top: 0,
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0,0,0,.5)",
        zIndex: 2,
      }}
      elevation={0}
    >
      <Card sx={{ mx: 3, mt: 6 }}>
        {session.user?.isGuest === true && (
          <Typography align="center">
            Por favor,{" "}
            <Link component={RouterLink} to="/signin" target="_blank">
              inicia sesión
            </Link>{" "}
            o{" "}
            <Link component={RouterLink} to="/signup" target="_blank">
              crea una cuenta
            </Link>{" "}
            para poder usar el chat.
          </Typography>
        )}
        {session.user?.isGuest === false && (
          <Typography align="center">
            Por favor,{" "}
            <Link component={RouterLink} to="/signin" target="_blank">
              verifica tu correo electrónico
            </Link>{" "}
            para poder usar el chat.
          </Typography>
        )}
        <Typography align="center">
          Gracias por ayudarnos a prevenir el spam.
        </Typography>
      </Card>
    </Card>
  );
}
