import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Add as AddIcon } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export default function GameFinished() {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Box
        sx={{ p: 0.5, flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Card sx={{ backgroundColor: "#e3dcd4", py: 5, px: 1, flexGrow: 1 }}>
          <Typography variant="h4" align="center">
            Fin de la partida
          </Typography>
          <Grid container direction="column" spacing={2} sx={{ py: 3 }}>
            <Grid item container direction="row" justifyContent="center">
              <Grid item xs={12} sm={7} lg={6}>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  fullWidth
                  startIcon={<AddIcon />}
                  onClick={() => navigate("/game")}
                >
                  Crea una partida
                </Button>
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="center">
              <Grid item xs={12} sm={7} lg={6}>
                <Button
                  variant="contained"
                  color="inherit"
                  size="large"
                  fullWidth
                  startIcon={<FormatListBulletedIcon />}
                  onClick={() => navigate("/")}
                >
                  Lista de partidas
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Typography gutterBottom>
            La partida ha finalizado porque el anfitrión ha abandonado.
          </Typography>
        </Card>
      </Box>
    </Box>
  );
}
