import React from "react";

import categories from "./categories";
import ProgressBar from "./ProgressBar";
import useClassicRoom from "./useClassicRoom";
import { Box, Card, Typography } from "@mui/material";

interface Props {
  category: string;
  questionIndex: number;
  questionCount: number;
  inProgress: boolean;
  beginTime: number;
  endTime: number;
}

export default function QuestionHeader(props: Props) {
  const room = useClassicRoom();
  const category = React.useMemo(
    () => categories.find((category) => category.categoryId === props.category),
    [props.category]
  );
  return (
    <Card
      sx={{ backgroundColor: "#e3dcd4", display: "flex", flexDirection: "row" }}
    >
      <Box
        component="img"
        sx={{ height: "80px", width: "80px" }}
        src={category?.image}
        alt={category?.name}
      />
      <Box sx={{ flexGrow: 1, px: 0.5 }}>
        <Typography align="right">
          Pregunta {props.questionIndex} de {props.questionCount}
        </Typography>
        <Typography variant="h5">{category?.name}</Typography>
        {props.inProgress ? (
          <ProgressBar
            beginTime={props.beginTime + room.timeDiff}
            endTime={props.endTime + room.timeDiff}
          />
        ) : null}
      </Box>
    </Card>
  );
}
