import { Button } from "@mui/material";

type Status = "none" | "selected" | "correct" | "incorrect";

function getColorClass(status: Status): string {
  if (status === "selected") {
    return "btn-warning";
  }
  if (status === "correct") {
    return "btn-success";
  }
  if (status === "incorrect") {
    return "btn-danger";
  }
  return "btn-outline-dark";
}

interface Props {
  status: "none" | "selected" | "correct" | "incorrect";
  text: string;
  onSelect: undefined | (() => void);
}

export default function Answer(props: Props) {
  return (
    <Button
      sx={{
        mt: 1,
        textAlign: "left",
        textTransform: "none",
        justifyContent: "flex-start",
      }}
      variant={(() => {
        if (
          props.status === "selected" ||
          props.status === "correct" ||
          props.status === "incorrect"
        ) {
          return "contained";
        }
        return "outlined";
      })()}
      fullWidth
      onClick={props.onSelect}
      color={(() => {
        if (props.status === "selected") {
          return "warning";
        }
        if (props.status === "correct") {
          return "success";
        }
        if (props.status === "incorrect") {
          return "error";
        }
        return "inherit";
      })()}
    >
      {props.text}
    </Button>
  );
}
