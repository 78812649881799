import React from "react";
import pRetry from "p-retry";

const minTimeoutMs = 1000; // 1 second
const maxTimeoutMs = 60000; // 60 seconds

interface None {
  status: "none";
}

interface Loading {
  status: "loading";
}

interface Success {
  status: "success";
  content: any;
}

interface Error {
  status: "error";
  error: any;
}

export type Result = None | Loading | Success | Error;

export default function useGetApi(path?: string, params?: any): Result {
  const [status, setStatus] = React.useState<
    "none" | "loading" | "success" | "error"
  >("none");
  const [content, setContent] = React.useState<any>(undefined);
  const [error, setError] = React.useState<any>(undefined);

  React.useEffect(() => {
    if (path === undefined) {
      return;
    }
    let subscribed = true;
    const controller = new AbortController();
    const execute = async (_attemptCount: number) => {
      const query =
        params === undefined
          ? ""
          : `?${new URLSearchParams(params).toString()}`;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${path}${query}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
          signal: controller.signal,
        }
      );
      return await response.json();
    };
    setStatus("loading");
    pRetry(execute, {
      onFailedAttempt: console.error,
      minTimeout: minTimeoutMs,
      maxTimeout: maxTimeoutMs,
      forever: true,
    })
      .then((content) => {
        if (subscribed) {
          setStatus("success");
          setContent(content);
        }
      })
      .catch((error) => {
        if (subscribed) {
          setStatus("error");
          setError(error);
          console.error(error);
        }
      });
    return () => {
      subscribed = false;
      controller.abort();
      setStatus("none");
      setContent(undefined);
      setError(undefined);
    };
  }, [path, params]);

  const result = React.useMemo<Result>(() => {
    if (status === "loading") {
      return {
        status: "loading",
      };
    }
    if (status === "success") {
      return {
        status: "success",
        content,
      };
    }
    if (status === "error") {
      return {
        status: "error",
        error,
      };
    }
    return {
      status: "none",
    };
  }, [status, content, error]);

  return result;
}
