import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
  Sheet,
  Table,
  Typography,
} from "@mui/joy";
import React from "react";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead, { HeadCell } from "./EnhancedTableHead";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import EnhancedTableTextCell from "./EnhancedTableTextCell";

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

type Order = "asc" | "desc";

interface Props<Data> {
  title: string;
  headCells: readonly HeadCell<Data>[];
  rows: Data[];
  rowCount: number;
  order: Order;
  setOrder: (order: Order) => void;
  orderBy: keyof Data;
  setOrderBy: (orderBy: keyof Data) => void;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  loading: boolean;
  actions?: React.ReactNode;
}

export default function EnhancedTable<
  Data extends Record<string, any> & { _id: any }
>(props: Props<Data>) {
  const {
    title,
    headCells,
    rows,
    rowCount,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    loading,
    actions,
  } = props;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    return Math.min(rowCount, (page + 1) * rowsPerPage);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, rowsPerPage - rows.length);

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: "100%",
        boxShadow: "sm",
        borderRadius: "sm",
        overflow: "auto",
      }}
    >
      <EnhancedTableToolbar title={title} loading={loading} actions={actions} />
      <Table
        hoverRow
        sx={{
          "--TableCell-headBackground": "transparent",
          "--TableCell-selectedBackground": (theme) =>
            theme.vars.palette.info.softBg,
        }}
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          headCells={headCells}
        />
        <tbody>
          {rows.map((row, index) => {
            return (
              <tr tabIndex={-1} key={row._id}>
                {headCells.map((headCell) => {
                  return (
                    <td key={headCell.id.toString()}>
                      {headCell.render === undefined ? (
                        <EnhancedTableTextCell
                          value={row[headCell.id].toString()}
                        />
                      ) : (
                        headCell.render(row)
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {emptyRows > 0 && (
            <tr
              style={
                {
                  height: `calc(${emptyRows} * 53px)`,
                  "--TableRow-hoverBackground": "transparent",
                } as React.CSSProperties
              }
            >
              <td colSpan={headCells.length} />
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={headCells.length}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <FormControl orientation="horizontal" size="sm">
                  <FormLabel>Rows per page:</FormLabel>
                  <Select
                    onChange={handleChangeRowsPerPage}
                    value={rowsPerPage}
                  >
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                  </Select>
                </FormControl>
                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
                    to: getLabelDisplayedRowsTo(),
                    count: rowCount,
                  })}
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: "background.surface" }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page >= Math.ceil(rowCount / rowsPerPage) - 1}
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: "background.surface" }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
  );
}
