import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import useTitle from "./useTitle";
import { recoverPasswordOtpTokenLength, otpTokenRegex } from "./constants";
import useAnalytics from "./useAnalytics";

interface Props {
  email: string;
  onComplete: (token: string) => void;
}

export default function RecoverPageToken(props: Props) {
  useTitle("Recupera tu cuenta");

  const snackbar = useSnackbar();
  const analytics = useAnalytics();

  // Token prompt
  const [token, setToken] = React.useState<string>("");
  const [showTokenError, setShowTokenError] = React.useState<boolean>(false);
  const tokenError = React.useMemo(() => {
    if (
      !otpTokenRegex.test(token) ||
      token.length !== recoverPasswordOtpTokenLength
    ) {
      return `Ha de tener ${recoverPasswordOtpTokenLength} dígitos`;
    }
  }, [token]);

  // Form validation
  const canSubmit = tokenError === undefined;

  // Submit
  const validateTokenApi = useApi();

  return (
    <>
      <Typography component="p" variant="body1">
        Hemos enviado un código de verificación a tu correo electrónico.
        Introdúcelo a continuación para recuperar tu cuenta.
      </Typography>
      <Box sx={{ mt: 3, alignSelf: "stretch" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Codigo de verificación"
              error={(() => showTokenError && tokenError !== undefined)()}
              helperText={(() => {
                if (!showTokenError) {
                  return undefined;
                }
                return tokenError;
              })()}
              value={token}
              onChange={(event) => {
                setToken(event.target.value);
                setShowTokenError(true);
              }}
              inputProps={{
                maxLength: recoverPasswordOtpTokenLength,
                inputMode: "numeric",
                style: {
                  textAlign: "center",
                  fontSize: "32px",
                },
              }}
              autoComplete="one-time-code"
            />
          </Grid>
        </Grid>
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          disabled={!canSubmit}
          loading={validateTokenApi.loading}
          onClick={
            validateTokenApi.loading
              ? undefined
              : () => {
                  validateTokenApi.post({
                    path: "/recover/verify-token",
                    params: { email: props.email, token },
                    onSuccess: (response) => {
                      snackbar.success("Código correcto");
                      props.onComplete(token);
                      analytics.event("recover_token_valid");
                    },
                    onError: (error) => {
                      if (error === "invalid token") {
                        snackbar.error("Código incorrecto");
                        analytics.event("recover_token_invalid");
                      } else if (error === "too many attempts") {
                        snackbar.error(
                          "Demasiados intentos seguidos, vuelve a probar mas tarde"
                        );
                        analytics.event("recover_token_too_many_attempts");
                      } else {
                        analytics.event("recover_token_error");
                      }
                    },
                  });
                }
          }
        >
          Continuar
        </LoadingButton>
      </Box>
    </>
  );
}
