import React from "react";
import { useNavigate } from "react-router-dom";
import categories from "./categories";
import Page from "./Page";
import SettingsWidget from "./SettingsWidget";
import TopContainer from "./TopContainer";
import { Settings } from "./types";
import useAnalytics from "./useAnalytics";
import useCreateRoom from "./useCreateRoom";
import useSession from "./useSession";
import useSnackbar from "./useSnackbar";
import { Box } from "@mui/material";

export default function CreateGamePage() {
  const session = useSession();
  const initialSettings = React.useMemo<Settings>(
    () => ({
      title: `Partida de ${session.user?.displayName}`,
      visibility: "public",
      questionCount: 10,
      answerTime: 15,
      categories: categories.map((category) => category.categoryId),
    }),
    [session]
  );
  const navigate = useNavigate();
  const createRoom = useCreateRoom();
  const snackbar = useSnackbar();
  const analytics = useAnalytics();

  return (
    <Page title="Crea una partida">
      <TopContainer title="Crea una partida">
        <Box sx={{ width: "100%" }}>
          <SettingsWidget
            initialSettings={initialSettings}
            onSave={async (settings) => {
              let roomId = null;
              try {
                roomId = await createRoom("classic", settings);
              } catch (error) {
                snackbar.error("Ha ocurrido un error. Inténtalo de nuevo.");
                return;
              }
              navigate(`/game/${roomId}`);
              analytics.event("game_created");
            }}
            submitButtonText="Crear partida"
            onCancel={() => {
              navigate("/");
            }}
          />
        </Box>
      </TopContainer>
    </Page>
  );
}
