import React from "react";
import { messageMaxLength } from "./constants";
import useAnalytics from "./useAnalytics";
import useClassicRoom from "./useClassicRoom";
import useSession from "./useSession";
import { Box, Divider, IconButton, InputBase, Paper } from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";

export default function ChatInput() {
  const room = useClassicRoom();
  const [message, setMessage] = React.useState("");
  const analytics = useAnalytics();
  const session = useSession();
  const sendMessage = () => {
    if (message.length === 0) {
      return;
    }
    room.send("message", message);
    analytics.event("message_sent");
    setMessage("");
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <InputBase
        type="text"
        sx={{ ml: 1, flex: 1 }}
        placeholder="Escribe tu mensaje aquí..."
        inputProps={{
          "aria-label": "escribe tu mensaje aquí",
          maxLength: messageMaxLength,
        }}
        value={message}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            sendMessage();
            event.preventDefault();
          }
        }}
      />
      <IconButton color="inherit" aria-label="enviar" onClick={sendMessage}>
        <SendIcon />
      </IconButton>
    </Paper>
  );
}
