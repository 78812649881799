import { Box, Card, CircularProgress, Typography } from "@mui/material";
import React from "react";

export default function GamePrepareGuest() {
  return (
    <Box sx={{ p: 0.5, flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Card
        sx={{
          backgroundColor: "#e3dcd4",
          py: 5,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress size={60} color="inherit" />
        <Typography sx={{ mt: 3 }}>
          <strong>Esperando a que el anfitrión inicie la partida</strong>
        </Typography>
      </Card>
    </Box>
  );
}
