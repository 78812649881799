import { Button, IconButton, ListItem, ListItemText } from "@mui/material";
import { Done as DoneIcon } from "@mui/icons-material";
import React from "react";
import useGetApi from "./useGetApi";
import { useNavigate } from "react-router-dom";
import useSession from "./useSession";

export default function SettingsEmailListItem() {
  const navigate = useNavigate();
  const session = useSession();
  const api = useGetApi("/settings/email");
  const email = api.status === "success" ? api.content.email : "...";
  return (
    <ListItem
      secondaryAction={
        <>
          {session.user?.emailVerified === true && (
            <Button color="success" disabled={true} startIcon={<DoneIcon />}>
              Verificado
            </Button>
          )}
          {session.user?.emailVerified === false && (
            <Button color="primary" onClick={() => navigate("/verify")}>
              Verificar
            </Button>
          )}
        </>
      }
    >
      <ListItemText primary="Correo electrónico" secondary={email} />
    </ListItem>
  );
}
