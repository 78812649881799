import React from "react";
import useSession from "./useSession";
import { Box, Card } from "@mui/material";

interface Props {
  userId: string;
  displayName: string;
  messageText: string;
}

const ChatMessage = (props: Props) => {
  const session = useSession();
  const self = session.user?.userId === props.userId;
  if (self) {
    return (
      <Box sx={{ px: 0.5, textAlign: "right" }}>
        <Card
          sx={{
            display: "inline-block",
            backgroundColor: "#dbf8c6",
            maxWidth: "100%",
            overflowWrap: "break-word",
            px: 0.5,
          }}
          elevation={0}
        >
          {props.messageText}
        </Card>
      </Box>
    );
  }
  return (
    <Box sx={{ px: 1, textAlign: "left" }}>
      <Card
        sx={{
          display: "inline-block",
          backgroundColor: "#ffffff",
          maxWidth: "100%",
          overflowWrap: "break-word",
          px: 0.5,
        }}
        elevation={0}
      >
        <small>
          <strong>{props.displayName}</strong>
        </small>
        <br />
        {props.messageText}
      </Card>
    </Box>
  );
};

export default ChatMessage;
