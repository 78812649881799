import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import {
  Settings as SettingsIcon,
  Done as DoneIcon,
  Login as LoginIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import logo from "./logo.png";
import useSession from "./useSession";
import NameChangeMenuItem from "./NameChangeMenuItem";
import Avatar from "./Avatar";
import SignOutMenuItem from "./SignOutMenuItem";

function Header() {
  const navigate = useNavigate();
  const session = useSession();
  const [userMenuOpen, setUserMenuOpen] = React.useState(false);
  const userMenuAnchor = React.useRef(null);
  const isGuest = session.user?.isGuest === true;
  const isAdmin = session.user?.isAdmin === true;
  const needsVerification =
    session.user?.isGuest === false && session.user?.emailVerified === false;
  return (
    <AppBar
      position="relative"
      color="transparent"
      sx={{ backdropFilter: "blur(20px)", mb: 3 }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0, mr: 1 }}>
            <Link
              variant="h5"
              component={RouterLink}
              to="/"
              sx={{
                flexGrow: 0,
              }}
            >
              <Box sx={{ height: 48 }} component="img" src={logo} />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, mr: 1 }}>
            <Link
              variant="h5"
              component={RouterLink}
              noWrap
              to="/"
              sx={{
                color: "white",
                textDecoration: "none",
                display: { xs: "none", md: "flex" },
              }}
            >
              TrivialOnline.es
            </Link>
          </Box>
          {isGuest && (
            <Box
              sx={{ flexGrow: 0, mr: 1, display: { xs: "none", sm: "flex" } }}
            >
              <Button
                component={RouterLink}
                to="/signup"
                variant="contained"
                color="primary"
              >
                Crear cuenta
              </Button>
            </Box>
          )}
          {isGuest && (
            <Box
              sx={{ flexGrow: 0, mr: 1, display: { xs: "none", sm: "flex" } }}
            >
              <Button
                component={RouterLink}
                to="/signin"
                variant="contained"
                color="success"
              >
                Iniciar sesión
              </Button>
            </Box>
          )}
          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              ref={userMenuAnchor}
              onClick={() => setUserMenuOpen(true)}
              sx={{ p: 0 }}
            >
              <Avatar />
            </IconButton>
            <Menu
              anchorEl={userMenuAnchor.current}
              sx={{ mt: "45px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={userMenuOpen}
              onClose={() => setUserMenuOpen(false)}
            >
              <MenuItem>
                <Box sx={{ mr: 2 }}>
                  <Avatar />
                </Box>
                {session.user?.displayName}
              </MenuItem>
              <Divider />
              {isGuest && (
                <MenuItem onClick={() => navigate("/signup")}>
                  <ListItemIcon>
                    <PersonAddIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Crear cuenta</ListItemText>
                </MenuItem>
              )}
              {isGuest && (
                <MenuItem onClick={() => navigate("/signin")}>
                  <ListItemIcon>
                    <LoginIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Iniciar sesión</ListItemText>
                </MenuItem>
              )}
              {needsVerification && (
                <MenuItem onClick={() => navigate("/verify")}>
                  <ListItemIcon>
                    <DoneIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Verificar correo</ListItemText>
                </MenuItem>
              )}
              <NameChangeMenuItem onComplete={() => setUserMenuOpen(false)} />
              {!isGuest && (
                <MenuItem
                  onClick={() => {
                    navigate("/settings");
                    setUserMenuOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Ajustes</ListItemText>
                </MenuItem>
              )}
              {isAdmin && (
                <MenuItem onClick={() => navigate("/admin")}>
                  <ListItemIcon>
                    <VpnKeyIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Administración</ListItemText>
                </MenuItem>
              )}
              {!isGuest && (
                <SignOutMenuItem onComplete={() => setUserMenuOpen(false)} />
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
