import React from "react";

export enum RoomStatus {
  disconnected,
  connecting,
  connected,
  notFound,
  error,
}

export interface Room<State> {
  status: RoomStatus;
  roomId: string | null;
  sessionId: string | null;
  state: State | null;
  send: (type: string, message?: any) => void;
  on: (type: string, listener: (message: any) => void) => void;
  off: (type: string, listener: (message: any) => void) => void;
  leave: () => void;
  timeDiff: number;
}

export default function createRoomContext<
  State
>(): React.Context<Room<State> | null> {
  return React.createContext<Room<State> | null>(null);
}
