import React from "react";
import SignedInPage from "./SignedInPage";
import { Card, Divider, List } from "@mui/material";
import SettingsEmailListItem from "./SettingsEmailListItem";
import SettingsPasswordListItem from "./SettingsPasswordListItem";
import SettingsDisplayNameListItem from "./SettingsDisplayNameListItem";
import SettingsUsernameListItem from "./SettingsUsernameListItem";
import SettingsDeleteAccountListItem from "./SettingsDeleteAccountListItem";

export default function SettingsPage() {
  return (
    <SignedInPage title="Ajustes">
      <Card>
        <List sx={{ mx: "auto", maxWidth: 600 }}>
          <SettingsEmailListItem />
          <SettingsPasswordListItem />
          <SettingsDisplayNameListItem />
          <SettingsUsernameListItem />
          <Divider />
          <SettingsDeleteAccountListItem />
        </List>
      </Card>
    </SignedInPage>
  );
}
