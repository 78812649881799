import { Box, Card, Typography } from "@mui/joy";
import AdminLayout from "./AdminLayout";
import AdminContestCreateForm from "./AdminContestCreateForm";

export default function AdminContestCreatePage() {
  return (
    <AdminLayout>
      <Box sx={{ my: 2 }}>
        <Card>
          <Typography level="h6" gutterBottom>
            New contest
          </Typography>
          <AdminContestCreateForm />
        </Card>
      </Box>
    </AdminLayout>
  );
}
