import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SnackbarContext from "./SnackbarContext";

const autoHideDurationMs = 6000;

interface Props {
  children: React.ReactNode;
}

type MessageType = "success" | "error";

interface SnackbarMessage {
  message: string;
  key: number;
  type: MessageType;
  sticky: boolean;
}

export default function SnackbarProvider(props: Props) {
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>(
    []
  );
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<
    SnackbarMessage | undefined
  >(undefined);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleNewMessage = React.useCallback(
    (type: MessageType, message: string, sticky: boolean) => {
      setSnackPack((prev) => [
        ...prev,
        { message, key: new Date().getTime(), type, sticky },
      ]);
    },
    [setSnackPack]
  );

  const handleClose = React.useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    },
    [setOpen]
  );

  const handleExited = React.useCallback(() => {
    setMessageInfo(undefined);
  }, [setMessageInfo]);

  const context = React.useMemo(
    () => ({
      success: (message: string) => handleNewMessage("success", message, false),
      error: (message: string) => handleNewMessage("error", message, false),
      stickyError: (message: string) =>
        handleNewMessage("error", message, true),
    }),
    [handleNewMessage]
  );

  return (
    <>
      <SnackbarContext.Provider value={context}>
        {props.children}
      </SnackbarContext.Provider>
      {messageInfo === undefined ? undefined : (
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          open={open}
          autoHideDuration={messageInfo.sticky ? undefined : autoHideDurationMs}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
        >
          <Alert
            onClose={handleClose}
            severity={messageInfo.type}
            sx={{ width: "100%" }}
          >
            {messageInfo.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
