import { Button, IconButton, ListItem, ListItemText } from "@mui/material";
import { Done as DoneIcon } from "@mui/icons-material";
import React from "react";
import useGetApi from "./useGetApi";
import { useNavigate } from "react-router-dom";
import useSession from "./useSession";
import { Edit as EditIcon } from "@mui/icons-material";
import UsernameChangeModal from "./UsernameChangeModal";

export default function SettingsUsernameListItem() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [newUsername, setNewUsername] = React.useState<string | null>(null);
  const api = useGetApi("/settings/username");
  const username =
    newUsername !== null
      ? newUsername
      : api.status === "success"
      ? api.content.username
      : "...";
  return (
    <>
      <ListItem
        secondaryAction={
          <Button
            color="inherit"
            startIcon={<EditIcon />}
            onClick={() => setOpen(true)}
          >
            Cambiar
          </Button>
        }
      >
        <ListItemText primary="Nombre de usuario" secondary={username} />
      </ListItem>
      {open && (
        <UsernameChangeModal
          initialUsername={username}
          open={open}
          onClose={(newUsername) => {
            setOpen(false);
            if (newUsername !== null) {
              setNewUsername(newUsername);
            }
          }}
        />
      )}
    </>
  );
}
