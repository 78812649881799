import { CircularProgress, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

interface Props {
  status: string;
}

export default function ScoreboardItemStatus(props: Props) {
  if (props.status === "correctAnswer") {
    return (
      <Typography sx={{ color: "green" }}>
        <DoneIcon sx={{ fontSize: 18 }} /> Respuesta correcta
      </Typography>
    );
  }
  if (props.status === "incorrectAnswer") {
    return (
      <Typography color="error">
        <CloseIcon sx={{ fontSize: 18 }} /> Respuesta incorrecta
      </Typography>
    );
  }
  if (props.status === "didNotAnswer") {
    return (
      <Typography sx={{ color: "gray" }}>
        <AccessTimeIcon sx={{ fontSize: 18 }} /> No respondió
      </Typography>
    );
  }
  if (props.status === "waiting") {
    return (
      <Typography sx={{ color: "gray" }}>
        <CircularProgress size={14} color="inherit" /> Esperando respuesta
      </Typography>
    );
  }
  if (props.status === "rematch") {
    return (
      <Typography sx={{ color: "green" }}>
        <ReplayIcon sx={{ fontSize: 18 }} /> ¡Revancha!
      </Typography>
    );
  }
  return null;
}
