import { Box, Card, Typography } from "@mui/material";
import React from "react";
import background from "./escheresque_ste.png";

interface Props {
  title?: string;
  children: React.ReactNode;
}

function TopContainer(props: Props) {
  return (
    <Card sx={{ backgroundImage: `url(${background})` }}>
      <Box sx={{ p: 1, textAlign: "center" }}>
        {props.title === undefined ? null : (
          <Typography variant="h5" sx={{ color: "white" }}>
            {props.title}
          </Typography>
        )}
      </Box>
      {props.children}
    </Card>
  );
}

export default TopContainer;
