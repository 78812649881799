import React from "react";
import useAnalytics from "./useAnalytics";
import useClassicRoom from "./useClassicRoom";
import { Box, Button, Link, Typography } from "@mui/material";
import { Flag as FlagIcon } from "@mui/icons-material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

interface Props {
  questionId: string;
}

export default function QuestionReport(props: Props) {
  const room = useClassicRoom();
  const [reported, setReported] = React.useState(false);
  React.useEffect(() => {
    setReported(false);
  }, [props.questionId]);
  const analytics = useAnalytics();

  if (reported) {
    return (
      <Typography sx={{ mt: 2 }} align="right">
        <ThumbUpIcon /> ¡Gracias por reportar!
      </Typography>
    );
  }

  return (
    <Box sx={{ mt: 1, display: "flex", justifyContent: "right" }}>
      <Button
        onClick={() => {
          setReported(true);
          room.send("reportQuestion", props.questionId);
          analytics.event("question_reported", {
            question_id: props.questionId,
          });
        }}
        startIcon={<FlagIcon />}
        color="inherit"
      >
        Reportar pregunta
      </Button>
    </Box>
  );
}
