import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";
import { Card } from "@mui/material";
import logo from "./logo.png";
import useTitle from "./useTitle";
import RecoverPageEmail from "./RecoverPageEmail";
import RecoverPageToken from "./RecoverPageToken";
import RecoverPagePassword from "./RecoverPagePassword";
import FullPageBackground from "./FullPageBackground";

export default function RecoverPage() {
  useTitle("Recupera tu cuenta");

  const [email, setEmail] = React.useState<string | null>(null);
  const [token, setToken] = React.useState<string | null>(null);

  return (
    <FullPageBackground>
      <Container component="main" maxWidth="xs">
        <Card
          sx={{
            marginTop: 8,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box component="img" sx={{ height: 80 }} src={logo} alt="Logo" />
          <Typography component="h1" variant="h5">
            Recupera tu cuenta
          </Typography>
          {email === null && <RecoverPageEmail onComplete={setEmail} />}
          {email !== null && token === null && (
            <RecoverPageToken email={email} onComplete={setToken} />
          )}
          {email !== null && token !== null && (
            <RecoverPagePassword email={email} token={token} />
          )}
          <Box sx={{ mt: 2 }}>
            <Copyright />
          </Box>
        </Card>
      </Container>
    </FullPageBackground>
  );
}
