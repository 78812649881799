import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import HomeIcon from "@mui/icons-material/Home";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PersonIcon from "@mui/icons-material/Person";
import useSession from "./useSession";
import useGetApi from "./useGetApi";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AdminSidebarListItemButton from "./AdminSidebarListItemButton";
import FlagIcon from "@mui/icons-material/Flag";
import TimerIcon from "@mui/icons-material/Timer";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AdminSidebar(props: Props) {
  const session = useSession();
  const email = useGetApi("/settings/email");

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: "fixed",
          md: "sticky",
        },
        transform: {
          xs: props.open ? "none" : "translateX(-100%)",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 100,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 1.5,
        py: 3,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
        boxSizing: "border-box",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "224px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "256px",
            },
          },
        })}
      />
      <Box
        sx={(theme) => ({
          position: "fixed",
          zIndex: 98,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: theme.palette.background.body,
          opacity: props.open ? 0.8 : 0.0,
          transition: "opacity 0.4s",
          transform: {
            xs: props.open
              ? "translateX(calc(var(--Sidebar-width, 0px)))"
              : "translateX(-100%)",
            lg: "translateX(-100%)",
          },
        })}
        onClick={props.onClose}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Typography fontWeight="xl">TrivialOnline.es Admin</Typography>
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <List
          sx={{
            "--ListItem-radius": "8px",
            "--List-gap": "4px",
            "--List-nestedInsetStart": "40px",
          }}
        >
          <ListItem>
            <AdminSidebarListItemButton path="/admin/home">
              <ListItemDecorator>
                <HomeIcon />
              </ListItemDecorator>
              <ListItemContent>Home</ListItemContent>
            </AdminSidebarListItemButton>
          </ListItem>
          <ListItem>
            <AdminSidebarListItemButton path="/admin/users">
              <ListItemDecorator>
                <PersonIcon />
              </ListItemDecorator>
              <ListItemContent>Users</ListItemContent>
            </AdminSidebarListItemButton>
          </ListItem>
          <ListItem>
            <AdminSidebarListItemButton path="/admin/questions">
              <ListItemDecorator>
                <QuestionMarkIcon />
              </ListItemDecorator>
              <ListItemContent>Questions</ListItemContent>
            </AdminSidebarListItemButton>
          </ListItem>
          <ListItem>
            <AdminSidebarListItemButton path="/admin/reports">
              <ListItemDecorator>
                <FlagIcon />
              </ListItemDecorator>
              <ListItemContent>Reports</ListItemContent>
            </AdminSidebarListItemButton>
          </ListItem>
          <ListItem>
            <AdminSidebarListItemButton path="/admin/contests">
              <ListItemDecorator>
                <TimerIcon />
              </ListItemDecorator>
              <ListItemContent>Contests</ListItemContent>
            </AdminSidebarListItemButton>
          </ListItem>
        </List>
        <List
          sx={{
            mt: "auto",
            flexGrow: 0,
            "--ListItem-radius": "8px",
            "--List-gap": "8px",
          }}
        >
          <ListItem>
            <AdminSidebarListItemButton path="/">
              <ListItemDecorator>
                <ExitToAppIcon />
              </ListItemDecorator>
              <ListItemContent>Exit</ListItemContent>
            </AdminSidebarListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Avatar variant="outlined" />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography fontSize="sm" fontWeight="lg">
            {session.user?.displayName}
          </Typography>
          <Typography level="body3">
            {email.status === "success" && email.content.email}
          </Typography>
        </Box>
      </Box>
    </Sheet>
  );
}
