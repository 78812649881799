import { Box } from "@mui/material";
import background from "./background.jpg";

interface Props {
  children: React.ReactNode;
}

export default function FullPageBackground(props: Props) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundColor: "#185a8f",
        userSelect: "none",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.children}
    </Box>
  );
}
