import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";
import { Card, Chip, Divider } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import logo from "./logo.png";
import { passwordMaxLength } from "./constants";
import emailValidator from "email-validator";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import useSession from "./useSession";
import useTitle from "./useTitle";
import useAnalytics from "./useAnalytics";
import FullPageBackground from "./FullPageBackground";

export default function SignInPage() {
  useTitle("Inicia sesión");

  const [email, setEmail] = React.useState<string>("");
  const [showEmailError, setShowEmailError] = React.useState(false);
  const emailError = React.useMemo(() => {
    if (!emailValidator.validate(email)) {
      return "Correo electrónico inválido.";
    }
  }, [email]);

  const [password, setPassword] = React.useState<string>("");

  const canSubmit = emailError === undefined && password.length !== 0;

  const submitApi = useApi();
  const session = useSession();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  return (
    <FullPageBackground>
      <Container component="main" maxWidth="xs">
        <Card
          sx={{
            marginTop: 8,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box component="img" sx={{ height: 80 }} src={logo} alt="Logo" />
          <Typography component="h1" variant="h5">
            Inicia sesión
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Correo electrónico"
                  type="email"
                  autoComplete="email"
                  error={(() => {
                    if (!showEmailError) {
                      return false;
                    }
                    return emailError !== undefined;
                  })()}
                  helperText={(() => {
                    if (!showEmailError) {
                      return undefined;
                    }
                    if (emailError !== undefined) {
                      return emailError;
                    }
                  })()}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setShowEmailError(true);
                  }}
                  inputProps={{ inputMode: "email" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Contraseña"
                  type="password"
                  autoComplete="password"
                  inputProps={{ maxLength: passwordMaxLength }}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              disabled={!canSubmit}
              loading={submitApi.loading}
              onClick={
                submitApi.loading
                  ? undefined
                  : () => {
                      submitApi.post({
                        path: "/signin",
                        params: { email, password },
                        onSuccess: (response) => {
                          session.refresh();
                          snackbar.success("Has iniciado sesión");
                          navigate("/");
                          analytics.event("signin_success");
                        },
                        onError: (error) => {
                          if (error === "email not found") {
                            snackbar.error("Correo electrónico no registrado");
                            analytics.event("signin_email_not_found");
                          } else if (error === "wrong password") {
                            snackbar.error("Contraseña incorrecta");
                            analytics.event("signin_wrong_password");
                          } else if (error === "too many attempts") {
                            snackbar.error("Demasiados intentos seguidos");
                            analytics.event("signin_too_many_attempts");
                          } else {
                            analytics.event("signin_error");
                          }
                        },
                      });
                    }
              }
            >
              Iniciar sesión
            </LoadingButton>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs>
                <Link component={RouterLink} to="/recover" variant="body2">
                  No recuerdo mi contraseña
                </Link>
              </Grid>
              <Grid item>
                <Link component={RouterLink} to="/signup" variant="body2">
                  Crea una cuenta
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Copyright />
          </Box>
        </Card>
      </Container>
    </FullPageBackground>
  );
}
