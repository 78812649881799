import React from "react";

export interface AuthorizedUser {
  userId: string;
  displayName: string;
  isGuest: boolean;
  emailVerified: boolean;
  isAdmin: boolean;
}

interface Session {
  user: AuthorizedUser | null;
  refresh: () => void;
}

const SessionContext = React.createContext<Session>({
  user: null,
  refresh: () => {},
});

export default SessionContext;
