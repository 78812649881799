import EnhancedTable from "./EnhancedTable";
import { HeadCell } from "./EnhancedTableHead";
import React from "react";
import useGetApi from "./useGetApi";
import EnhancedTableDateCell from "./EnhancedTableDateCell";
import usePreviousFallback from "./usePreviousFallback";
import EnhancedTableLinkCell from "./EnhancedTableLinkCell";
import { Question } from "./AdminQuestionTypes";
import nullthrows from "nullthrows";
import { Button } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const headCells: readonly HeadCell<Question>[] = [
  {
    id: "_id",
    numeric: false,
    disablePadding: true,
    label: "Question ID",
    width: "240px",
    render: (data) => (
      <EnhancedTableLinkCell
        to={`/admin/questions/${data._id}`}
        label={nullthrows(data._id)}
      />
    ),
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
    width: "120px",
  },
  {
    id: "text",
    numeric: false,
    disablePadding: false,
    label: "Text",
    width: "320px",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    render: (data) =>
      data.createdAt && <EnhancedTableDateCell value={data.createdAt} />,
    width: "180px",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Updated At",
    render: (data) =>
      data.updatedAt && <EnhancedTableDateCell value={data.updatedAt} />,
    width: "180px",
  },
];

export default function AdminQuestionsTable() {
  const navigate = useNavigate();

  const [order, setOrder] = React.useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Question>("createdAt");
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const params = React.useMemo(
    () => ({ offset: page * rowsPerPage, limit: rowsPerPage, order, orderBy }),
    [page, rowsPerPage, order, orderBy]
  );
  const query = useGetApi("/admin/questions", params);
  const data = usePreviousFallback(
    query.status === "success" ? query.content : undefined
  );

  const { questions, total } = React.useMemo(() => {
    return {
      questions: data !== undefined ? (data.questions as Question[]) : [],
      total: data !== undefined ? (data.total as number) : 0,
    };
  }, [data]);

  return (
    <EnhancedTable
      title="Questions"
      headCells={headCells}
      rows={questions}
      rowCount={total}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      loading={query.status === "loading"}
      actions={
        <Button
          startDecorator={<AddIcon />}
          color="success"
          onClick={() => navigate("/admin/questions/new")}
        >
          New
        </Button>
      }
    />
  );
}
