import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider as BusProvider } from "react-bus";
import ContactPage from "./ContactPage";
import CreateGamePage from "./CreateGamePage";
import FaqPage from "./FaqPage";
import HomePage from "./HomePage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import SessionProvider from "./SessionProvider";
import TermsOfServicePage from "./TermsOfServicePage";
import SnackbarProvider from "./SnackbarProvider";
import ColyseusProvider from "./ColyseusProvider";
import GamePage from "./GamePage";
import NotFoundPage from "./NotFoundPage";
import OnlineStatusMonitor from "./OnlineStatusMonitor";
import AnalyticsProvider from "./AnalyticsProvider";
import SignUpPage from "./SignUpPage";
import SignInPage from "./SignInPage";
import VerifyPage from "./VerifyPage";
import RecoverPage from "./RecoverPage";
import SettingsPage from "./SettingsPage";
import AdminHomePage from "./AdminHomePage";
import nullthrows from "nullthrows";
import AdminUsersPage from "./AdminUsersPage";
import AdminQuestionsPage from "./AdminQuestionsPage";
import AdminQuestionEditPage from "./AdminQuestionEditPage";
import AdminQuestionCreatePage from "./AdminQuestionCreatePage";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import AdminReportsPage from "./AdminReportsPage";
import AdminContestsPage from "./AdminContestsPage";
import AdminContestCreatePage from "./AdminContestCreatePage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdminContestEditPage from "./AdminContestEditPage";

const materialTheme = materialExtendTheme();

export default function App() {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <BusProvider>
          <AnalyticsProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SnackbarProvider>
                <OnlineStatusMonitor />
                <SessionProvider>
                  <ColyseusProvider
                    address={nullthrows(process.env.REACT_APP_WS_URL)}
                  >
                    <BrowserRouter>
                      <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/game" element={<CreateGamePage />} />
                        <Route path="/game/:gameId" element={<GamePage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route
                          path="/contact.html"
                          element={<Navigate to="/contact" />}
                        />
                        <Route path="/faq" element={<FaqPage />} />
                        <Route
                          path="/faq.html"
                          element={<Navigate to="/faq" />}
                        />
                        <Route
                          path="/privacy-policy"
                          element={<PrivacyPolicyPage />}
                        />
                        <Route
                          path="/terms-of-service"
                          element={<TermsOfServicePage />}
                        />
                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/signin" element={<SignInPage />} />
                        <Route path="/verify" element={<VerifyPage />} />
                        <Route path="/recover" element={<RecoverPage />} />
                        <Route path="/recover" element={<RecoverPage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route
                          path="/admin"
                          element={<Navigate to="/admin/home" />}
                        />
                        <Route path="/admin/home" element={<AdminHomePage />} />
                        <Route
                          path="/admin/users"
                          element={<AdminUsersPage />}
                        />
                        <Route
                          path="/admin/questions"
                          element={<AdminQuestionsPage />}
                        />
                        <Route
                          path="/admin/questions/new"
                          element={<AdminQuestionCreatePage />}
                        />
                        <Route
                          path="/admin/questions/:questionId"
                          element={<AdminQuestionEditPage />}
                        />
                        <Route
                          path="/admin/reports"
                          element={<AdminReportsPage />}
                        />
                        <Route
                          path="/admin/contests"
                          element={<AdminContestsPage />}
                        />
                        <Route
                          path="/admin/contests/new"
                          element={<AdminContestCreatePage />}
                        />
                        <Route
                          path="/admin/contests/:contestId"
                          element={<AdminContestEditPage />}
                        />
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </BrowserRouter>
                  </ColyseusProvider>
                </SessionProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </AnalyticsProvider>
        </BusProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
