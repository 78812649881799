import * as React from "react";
import Box from "@mui/joy/Box";
import AdminSidebar from "./AdminSidebar";
import AdminHeader from "./AdminHeader";
import ForbiddenPage from "./ForbiddenPage";
import useSession from "./useSession";
import { CssVarsProvider } from "@mui/joy";
import adminTheme from "./adminTheme";

interface Props {
  children: React.ReactNode;
}

export default function AdminLayout(props: Props) {
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);

  const session = useSession();
  const isAdmin = session.user?.isAdmin === true;
  if (!isAdmin) {
    return <ForbiddenPage />;
  }

  return (
    <CssVarsProvider disableTransitionOnChange theme={adminTheme}>
      <Box
        sx={(theme) => ({
          display: "flex",
          minHeight: "100dvh",
          backgroundColor: theme.palette.background.body,
        })}
      >
        <AdminHeader openSidebar={() => setSidebarOpen(true)} />
        <AdminSidebar
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
        />
        <Box
          component="main"
          className="MainContent"
          sx={(theme) => ({
            "--main-paddingTop": {
              xs: `calc(${theme.spacing(2)} + var(--Header-height, 0px))`,
              md: "32px",
            },
            px: {
              xs: 2,
              md: 3,
            },
            pt: "var(--main-paddingTop)",
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            overflow: "auto",
          })}
        >
          {props.children}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
