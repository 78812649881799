import React from "react";
import { Contest } from "./AdminContestTypes";
import { Box, Button } from "@mui/joy";
import AdminContestInput, { isValidContest } from "./AdminContestInput";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import { useNavigate } from "react-router-dom";

interface Props {
  contest: Contest;
}

export default function AdminContestEditForm(props: Props) {
  const [contest, setContest] = React.useState(props.contest);
  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const valid = isValidContest(contest);
  const submit = () => {
    if (api.loading) {
      return;
    }
    api.patch({
      path: `/admin/contests/${contest._id}`,
      params: { contest },
      onSuccess: () => snackbar.success("Contest updated"),
    });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <AdminContestInput
        contest={contest}
        onChange={setContest}
        disabled={api.loading}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          gap: 2,
        }}
      >
        <Button variant="plain" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button disabled={!valid} loading={api.loading} onClick={submit}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
