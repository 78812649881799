import { Game } from "./types";
import categories from "./categories";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Card, Chip, Grid, Typography } from "@mui/material";
import {
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@mui/icons-material";

function getCategoryName(categoryId: string): string | undefined {
  return categories.find((category) => category.categoryId === categoryId)
    ?.name;
}

interface Props {
  game: Game;
}

export default function GameCard(props: Props) {
  const isFull = props.game.userCount >= props.game.maxUserCount;
  const allCategories = props.game.categories.length === categories.length;
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card sx={{ backgroundColor: "#e3dcd4", p: 1 }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h5" align="center">
              {props.game.title}
            </Typography>
          </Box>
          <Box>
            {props.game.paused ? (
              <PauseIcon titleAccess="Partida en pausa" />
            ) : (
              <PlayArrowIcon titleAccess="Partida en curso" />
            )}
          </Box>
        </Box>
        <Typography sx={{ my: 0.5 }}>
          <strong>Jugadores:</strong>{" "}
          <Chip
            sx={{ fontSize: 14 }}
            label={props.game.userCount}
            color={isFull ? "error" : "success"}
            size="small"
          />{" "}
          de {props.game.maxUserCount}
        </Typography>
        <Typography sx={{ my: 0.5 }}>
          <strong>Temas:</strong>{" "}
          {allCategories ? (
            <Chip
              sx={{ fontSize: 14, backgroundColor: "#ffc107" }}
              label="Todos los temas"
              size="small"
            />
          ) : (
            props.game.categories.map((category) => [
              <Chip
                key={category}
                sx={{ fontSize: 14, mr: 0.5, mb: 0.5 }}
                label={getCategoryName(category)}
                size="small"
              />,
            ])
          )}
        </Typography>
        <Typography sx={{ my: 0.5 }}>
          <strong>Preguntas:</strong> {props.game.questionCount}
        </Typography>
        <Typography sx={{ my: 0.5 }}>
          <strong>Tiempo de respuesta:</strong> {props.game.answerTime} segundos
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            component={RouterLink}
            to={`/game/${props.game.gameId}`}
            disabled={isFull}
          >
            ¡Entra!
          </Button>
        </Box>
      </Card>
    </Grid>
  );
}
