import React from "react";
import Page from "./Page";
import { Card } from "@mui/material";

function TermsOfServicePage() {
  return (
    <Page title="Términos de servicio">
      <Card sx={{ p: 3 }}>
        <p>
          <strong>Términos de Servicio</strong>
        </p>
        <p>&nbsp;</p>

        <p>
          <strong>Términos</strong>
        </p>
        <p>
          Al acceder al sitio web en trivialonline.es, usted acepta estar
          vinculado por estos términos de servicio, todas las leyes y
          regulaciones aplicables, y acepta que usted es responsable del
          cumplimiento de las leyes locales aplicables. Si no está de acuerdo
          con alguno de estos términos, se le prohíbe utilizar o acceder a este
          sitio. Los materiales contenidos en este sitio web están protegidos
          por las leyes de derechos de autor y marcas aplicables.
        </p>

        <p>
          <strong>Licencia de Uso</strong>
        </p>
        <p>
          Se concede permiso para descargar temporalmente una copia de los
          materiales (información o software) en el sitio web trivialonline.es
          para la visualización transitoria personal y no comercial solamente.
          Esta es la concesión de una licencia, no una transferencia de título,
          y bajo esta licencia usted no puede:
        </p>
        <p>
          Modificar o copiar los materiales; utilizar los materiales para
          cualquier propósito comercial, o para cualquier exhibición pública
          (comercial o no comercial); intentar descompilar o realizar ingeniería
          inversa de cualquier software contenido en el sitio web de
          trivialonline.es; eliminar cualquier Copyright u otras anotaciones
          propietarias de los materiales; o transferir los materiales a otra
          persona o “reflejar” los materiales en cualquier otro servidor.
        </p>
        <p>
          Esta licencia se terminará automáticamente si usted viola cualquiera
          de estas restricciones y puede ser terminada por trivialonline.es en
          cualquier momento. Al terminar su visualización de estos materiales o
          al finalizar esta licencia, debe destruir cualquier material
          descargado en su posesión ya sea en formato electrónico o impreso.
        </p>

        <p>
          <strong>Responsabilidad</strong>
        </p>
        <p>
          Los materiales del sitio web de trivialonline.es se proporcionan “tal
          cual”. trivialonline.es no otorga ninguna garantía, expresa o
          implícita, y por la presente renuncia y niega todas las demás
          garantías, incluyendo, sin limitación, garantías implícitas o
          condiciones de comerciabilidad, idoneidad para un propósito en
          particular, o no infracción de propiedad intelectual u otra violación
          de derechos.
        </p>
        <p>
          Además, trivialonline.es no garantiza ni hace ninguna representación
          con respecto a la exactitud, los resultados probables, o la
          confiabilidad del uso de los materiales en su sitio web o de otra
          manera relacionado con tales materiales o en cualquier sitio vinculado
          a este lugar.
        </p>

        <p>
          <strong>Limitaciones</strong>
        </p>
        <p>
          En ningún caso trivialonline.es o sus proveedores serán responsables
          de ningún daño (incluyendo, sin limitación, daños por pérdida de datos
          o beneficio, o debido a la interrupción del negocio) que surjan del
          uso o la incapacidad de utilizar los materiales en el sitio web de
          trivialonline.es, incluso si trivialonline.es o un representante
          autorizado de trivialonline.es ha sido notificado verbalmente o por
          escrito de la posibilidad de tal daño. Debido a que algunas
          jurisdicciones no permiten limitaciones a las garantías implícitas, o
          limitaciones de responsabilidad por daños consecuentes o incidentales,
          es posible que estas limitaciones no se apliquen a usted.
        </p>

        <p>
          <strong>Exactitud de Materiales</strong>
        </p>
        <p>
          Los materiales que aparecen en el sitio web de trivialonline.es pueden
          incluir errores técnicos, tipográficos o fotográficos.
          trivialonline.es no garantiza que cualquiera de los materiales en su
          sitio web son exactos, completos o actuales. trivialonline.es puede
          realizar cambios en los materiales contenidos en su sitio web en
          cualquier momento sin previo aviso. Sin embargo trivialonline.es no se
          compromete a actualizar los materiales.
        </p>

        <p>
          <strong>Enlaces</strong>
        </p>
        <p>
          trivialonline.es no ha revisado todos los sitios vinculados a su sitio
          web y no es responsable de los contenidos de dicho sitio vinculado.
          trivialonline.es puede Agregar vínculo (s) o texto al código
          incrustado. La inclusión de cualquier enlace no implica la aprobación
          por trivialonline.es del sitio. El uso de cualquier sitio web
          vinculado es bajo el propio riesgo del usuario.
        </p>

        <p>
          <strong>Modificaciones</strong>
        </p>
        <p>
          trivialonline.es puede revisar estos términos de servicio para su
          sitio web en cualquier momento sin previo aviso. Al utilizar este
          sitio web, usted acepta estar vinculado por la versión actual de estos
          términos de servicio.
        </p>

        <p>
          <strong>Ley Aplicable</strong>
        </p>
        <p>
          Estos términos y condiciones se rigen e interpretan de acuerdo con las
          leyes globales y usted se somete irrevocablemente a la jurisdicción
          exclusiva de los tribunales en ese estado o ubicación.
        </p>
      </Card>
    </Page>
  );
}

export default TermsOfServicePage;
