import artlit from "./artlit.png";
import cinematv from "./cinematv.png";
import geography from "./geography.png";
import history from "./history.png";
import miscellany from "./miscellany.png";
import music from "./music.png";
import science from "./science.png";
import sports from "./sports.png";

const categories = [
  {
    categoryId: "artlit",
    name: "Arte y Literatura",
    image: artlit,
  },
  {
    categoryId: "cinematv",
    name: "Cine y Televisión",
    image: cinematv,
  },
  {
    categoryId: "geography",
    name: "Geografía",
    image: geography,
  },
  {
    categoryId: "history",
    name: "Historia",
    image: history,
  },
  {
    categoryId: "miscellany",
    name: "Miscelánea",
    image: miscellany,
  },
  {
    categoryId: "music",
    name: "Música",
    image: music,
  },
  {
    categoryId: "science",
    name: "Ciencia y Naturaleza",
    image: science,
  },
  {
    categoryId: "sports",
    name: "Deporte",
    image: sports,
  },
];

export default categories;
