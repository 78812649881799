import AdminLayout from "./AdminLayout";
import AdminVerifiedSignups from "./AdminVerifiedSignups";

export default function AdminHomePage() {
  return (
    <AdminLayout>
      <AdminVerifiedSignups />
    </AdminLayout>
  );
}
