import { Box, Typography } from "@mui/joy";
import { LinearProgress } from "@mui/material";

interface Props {
  title: string;
  loading: boolean;
  actions?: React.ReactNode;
}

export default function EnhancedTableToolbar(props: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: 1,
        borderTopLeftRadius: "var(--unstable_actionRadius)",
        borderTopRightRadius: "var(--unstable_actionRadius)",
      }}
    >
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "row", px: 1 }}>
        <Typography sx={{ flexGrow: 1 }} level="h6" component="div">
          {props.title}
        </Typography>
        {props.actions}
      </Box>
      <Box
        sx={{
          alignSelf: "stretch",
          height: 1.5,
          width: "100%",
          mt: 1,
        }}
      >
        {props.loading && <LinearProgress sx={{ heigh: "100%" }} />}
      </Box>
    </Box>
  );
}
