import React from "react";
import { Player } from "./ClassicState.js";
import ScoreboardItem from "./ScoreboardItem";
import { Box, Card } from "@mui/material";

interface Props {
  players: Player[];
}

export default function Scoreboard(props: Props) {
  const players = React.useMemo(() => {
    const players = props.players.filter((player) => player.in);
    players.sort((player1, player2) => {
      if (player1.points !== player2.points) {
        return player1.points > player2.points ? -1 : 1;
      }
      if (player1.correctAnswers !== player2.correctAnswers) {
        return player1.correctAnswers > player2.correctAnswers ? -1 : 1;
      }
      return 0;
    });
    return players;
  }, [props.players]);
  return (
    <Card
      sx={{ backgroundColor: "#e3dcd4", maxHeight: "300px", overflowY: "auto" }}
    >
      {players.map((player, index) => (
        <Box
          key={player.userId}
          sx={{ borderTop: index === 0 ? "none" : "solid", borderWidth: 1 }}
        >
          <ScoreboardItem
            userId={player.userId}
            position={index + 1}
            host={player.host}
            displayName={player.displayName}
            points={player.points}
            correctAnswers={player.correctAnswers}
            status={player.status}
            joinedLate={player.joinedLate}
          />
        </Box>
      ))}
    </Card>
  );
}
