import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";
import { Card, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "./logo.png";
import { verifyEmailOtpTokenLength, otpTokenRegex } from "./constants";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import useSession from "./useSession";
import useTitle from "./useTitle";
import useAnalytics from "./useAnalytics";
import FullPageBackground from "./FullPageBackground";

const initialWaitTimeMs = 1000; // 1 second
const waitTimeMs = 30000; // 30 seconds
const refreshTimeMs = 100; // 100 ms
const sessionStorageKey = "token-generated-at";

export default function VerifyPage() {
  useTitle("Verifica tu correo electrónico");

  const session = useSession();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  // Token prompt
  const [token, setToken] = React.useState<string>("");
  const [showTokenError, setShowTokenError] = React.useState<boolean>(false);
  const tokenError = React.useMemo(() => {
    if (
      !otpTokenRegex.test(token) ||
      token.length !== verifyEmailOtpTokenLength
    ) {
      return `Ha de tener ${verifyEmailOtpTokenLength} dígitos`;
    }
  }, [token]);

  // Wait time
  const initialGeneratedAt = React.useMemo(() => {
    const stored = sessionStorage.getItem(sessionStorageKey);
    if (stored === null) {
      return 0;
    }
    return parseInt(stored);
  }, []);
  const initialWaitTimeSeconds = React.useMemo(
    () =>
      Math.ceil(
        Math.max(0, initialGeneratedAt + waitTimeMs - Date.now()) / 1000
      ),
    [initialGeneratedAt]
  );
  const [generatedAt, setGeneratedAt] = React.useState(initialGeneratedAt);
  const [waitTimeSeconds, setWaitTimeSeconds] = React.useState(
    initialWaitTimeSeconds
  );
  React.useEffect(() => {
    const update = () => {
      const now = Date.now();
      const waitTimeSeconds = Math.ceil(
        Math.max(0, generatedAt + waitTimeMs - now) / 1000
      );
      setWaitTimeSeconds(waitTimeSeconds);
      if (waitTimeSeconds === 0) {
        clearInterval(intervalId);
      }
    };
    const intervalId = setInterval(update, refreshTimeMs);
    return () => clearInterval(intervalId);
  }, [generatedAt, setWaitTimeSeconds]);

  // Form validation
  const canSubmit = tokenError === undefined;

  // Status
  const [status, setStatus] = React.useState<
    "none" | "loading" | "error" | "success"
  >("loading");

  // Generate token
  const generateTokenApi = useApi();
  const generateToken = React.useCallback(() => {
    if (generateTokenApi.loading) {
      return;
    }
    if (Date.now() - generatedAt < waitTimeMs) {
      return;
    }
    setStatus("loading");
    generateTokenApi.post({
      path: "/verify/generate-token",
      params: {},
      onSuccess: (response) => {
        setStatus("success");
        const now = Date.now();
        sessionStorage.setItem(sessionStorageKey, now.toString());
        setGeneratedAt(now);
        snackbar.success(`Código enviado a ${response.email}`);
        analytics.event("verify_token_sent");
      },
      onError: (error) => {
        setStatus("error");
        analytics.event("verify_token_error");
      },
    });
  }, [generateTokenApi, snackbar, setStatus]);
  React.useEffect(() => {
    const timeoutId = setTimeout(generateToken, initialWaitTimeMs);
    return () => clearTimeout(timeoutId);
  }, []);

  // Verify token
  const verifyTokenApi = useApi();

  return (
    <FullPageBackground>
      <Container component="main" maxWidth="xs">
        <Card
          sx={{
            marginTop: 8,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box component="img" sx={{ height: 80 }} src={logo} alt="Logo" />
          <Typography component="h1" variant="h5">
            Verifica tu correo electrónico
          </Typography>
          <Typography component="p" variant="body1">
            Hemos enviado un código de {verifyEmailOtpTokenLength} dígitos a tu
            correo electrónico. Copia el código a continuación para completar el
            proceso de verificación.
          </Typography>
          <Box sx={{ mt: 3, alignSelf: "stretch" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Codigo de verificación"
                  error={(() => showTokenError && tokenError !== undefined)()}
                  helperText={(() => {
                    if (!showTokenError) {
                      return undefined;
                    }
                    return tokenError;
                  })()}
                  value={token}
                  onChange={(event) => {
                    setToken(event.target.value);
                    setShowTokenError(true);
                  }}
                  inputProps={{
                    maxLength: verifyEmailOtpTokenLength,
                    inputMode: "numeric",
                    style: {
                      textAlign: "center",
                      fontSize: "32px",
                    },
                  }}
                  autoComplete="one-time-code"
                />
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              disabled={!canSubmit}
              loading={verifyTokenApi.loading}
              onClick={
                verifyTokenApi.loading
                  ? undefined
                  : () => {
                      verifyTokenApi.post({
                        path: "/verify/verify-token",
                        params: { token },
                        onSuccess: (response) => {
                          snackbar.success("Correo electrónico verificado");
                          session.refresh();
                          navigate("/");
                          analytics.event("verify_success");
                        },
                        onError: (error) => {
                          if (error === "invalid token") {
                            snackbar.error("Código incorrecto");
                            analytics.event("verify_invalid_token");
                          } else if (error === "too many attempts") {
                            snackbar.error(
                              "Demasiados intentos seguidos, vuelve a probar mas tarde"
                            );
                            analytics.event("verify_too_many_attempts");
                          } else {
                            analytics.event("verify_error");
                          }
                        },
                      });
                    }
              }
            >
              Verificar
            </LoadingButton>
          </Box>
          <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              {generateTokenApi.loading && (
                <Typography variant="body1">
                  Enviando código de verificación...
                </Typography>
              )}
              {status === "error" && (
                <Typography variant="body1" color="error">
                  Error enviando código.{" "}
                  <Link component="button" onClick={generateToken}>
                    Enviar de nuevo
                  </Link>
                </Typography>
              )}
              {(status === "none" || status === "success") && (
                <>
                  {waitTimeSeconds === 0 ? (
                    <Typography variant="body1">
                      ¿No lo has recibido?{" "}
                      <Link component="button" onClick={generateToken}>
                        Enviar de nuevo
                      </Link>
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      ¿No lo has recibido? Pide otro en{" "}
                      {waitTimeSeconds.toFixed(0)} segundos...
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Copyright />
          </Box>
        </Card>
      </Container>
    </FullPageBackground>
  );
}
