import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useApi from "./useApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useSnackbar from "./useSnackbar";
import { usernameMaxLength, usernameMinLength } from "./constants";
import useGetApi from "./useGetApi";
import { LoadingButton } from "@mui/lab";
import useAnalytics from "./useAnalytics";

interface Props {
  initialUsername: string;
  open: boolean;
  onClose: (newUsername: string | null) => void;
}

export default function UsernameChangeModal(props: Props) {
  const [username, setUsername] = React.useState<string>(props.initialUsername);
  const usernameError = React.useMemo(() => {
    const regex = /^[a-zA-Z0-9._]*$/;
    if (!regex.test(username)) {
      return "Solo puede contener letras, números, punto y guion bajo.";
    }
    if (username.length < usernameMinLength) {
      return "Demasiado corto.";
    }
    if (username.length > usernameMaxLength) {
      return "Demasiado largo.";
    }
  }, [username, props.initialUsername]);
  const checkUsernameParams = React.useMemo(() => ({ username }), [username]);
  const checkUsername = useGetApi(
    usernameError === undefined ? "/signup/check-username" : undefined,
    checkUsernameParams
  );
  const api = useApi();
  const snackbar = useSnackbar();
  const analytics = useAnalytics();

  React.useEffect(() => {
    if (props.open) {
      setUsername(props.initialUsername);
    }
  }, [props.open]);

  const canSubmit =
    username !== props.initialUsername && usernameError === undefined;

  return (
    <Dialog open={props.open} onClose={() => props.onClose(null)}>
      <DialogTitle>Cambiar nombre de usuario</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Elige un nombre de usuario único para identificar tu cuenta.
        </DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          type="text"
          fullWidth
          variant="outlined"
          value={username}
          onChange={(event: any) => setUsername(event.target.value)}
          autoComplete="nickname"
          inputProps={{ maxLength: usernameMaxLength }}
          error={(() => {
            if (usernameError !== undefined) {
              return true;
            }
            if (username === props.initialUsername) {
              return false;
            }
            if (
              checkUsername.status === "success" &&
              checkUsername.content.registered &&
              checkUsername.content.username !== props.initialUsername
            ) {
              return true;
            }
            if (checkUsername.status === "error") {
              return true;
            }
            return false;
          })()}
          helperText={(() => {
            if (usernameError !== undefined) {
              return usernameError;
            }
            if (checkUsername.status === "loading") {
              return "Comprobando disponibilidad...";
            }
            if (
              checkUsername.status === "success" &&
              checkUsername.content.registered &&
              checkUsername.content.username !== props.initialUsername
            ) {
              return "Este nombre de usuario no está disponible.";
            }
            if (checkUsername.status === "error") {
              return "Error inesperado, inténtalo más tarde.";
            }
            return "";
          })()}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose(null)}>Cancelar</Button>
        <LoadingButton
          variant="contained"
          disabled={!canSubmit}
          loading={api.loading}
          onClick={
            api.loading
              ? undefined
              : () =>
                  api.post({
                    path: "/settings/username",
                    params: { username },
                    onSuccess: () => {
                      props.onClose(username);
                      snackbar.success("Nombre de usuario actualizado");
                      analytics.event("username_changed");
                    },
                    onError: () => {
                      analytics.event("change_username_error");
                    },
                  })
          }
        >
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
