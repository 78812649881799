import { Link } from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  to: string;
  label: string;
}

export default function EnhancedTableLinkCell(props: Props) {
  return (
    <Link component={RouterLink} to={props.to}>
      {props.label}
    </Link>
  );
}
