import { Link as RouterLink } from "react-router-dom";
import useTitle from "./useTitle";
import { Container, Typography, Link } from "@mui/joy";

export default function ForbiddenPage() {
  useTitle("403 Forbidden");
  return (
    <Container>
      <Typography level="h1">403 Forbidden</Typography>
      <Typography>No tienes permiso para acceder a esta página.</Typography>
      <Typography>
        Vuelve a la{" "}
        <Link component={RouterLink} to="/">
          página principal
        </Link>
        .
      </Typography>
    </Container>
  );
}
