import { Box, Button, Card, Typography } from "@mui/material";
import GameList from "./GameList";
import Page from "./Page";
import logo from "./logo.png";
import background from "./escheresque_ste.png";
import { Add as AddIcon } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

function HomePage() {
  return (
    <Page title="TrivialOnline.es - Juego de Trivial online multijugador">
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          textAlign: "center",
          mb: 2,
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            height: { xs: "120px", lg: "180px" },
          }}
          component="img"
          src={logo}
          alt="Logo"
        />
        <Box
          sx={{
            color: "white",
            ml: 2,
            textAlign: "left",
            mt: { xs: 0, lg: 5 },
          }}
        >
          <Typography variant="h2">TrivialOnline.es</Typography>
          <Typography>
            Reta a tus amigos con el juego de preguntas multijugador en tiempo
            real
          </Typography>
        </Box>
      </Box>

      <Card
        sx={{
          background: `url(${background})`,
          mb: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          px: 1,
        }}
      >
        <Button
          component={RouterLink}
          variant="contained"
          color="success"
          size="large"
          startIcon={<AddIcon />}
          fullWidth
          sx={{
            maxWidth: "500px",
            my: 1,
            height: 50,
          }}
          to="/game"
        >
          Crea una partida
        </Button>
      </Card>
      <GameList />
    </Page>
  );
}

export default HomePage;
