import EnhancedTable from "./EnhancedTable";
import { HeadCell } from "./EnhancedTableHead";
import React from "react";
import useGetApi from "./useGetApi";
import usePreviousFallback from "./usePreviousFallback";
import EnhancedTableLinkCell from "./EnhancedTableLinkCell";
import nullthrows from "nullthrows";
import { Button } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Report } from "./AdminReportTypes";
import EnhancedTableTextCell from "./EnhancedTableTextCell";

const headCells: readonly HeadCell<Report>[] = [
  {
    id: "_id",
    numeric: false,
    disablePadding: true,
    label: "Question ID",
    width: "180px",
    render: (data) => (
      <EnhancedTableLinkCell
        to={`/admin/questions/${data._id}`}
        label={nullthrows(data._id)}
      />
    ),
  },
  {
    id: "questionText",
    numeric: false,
    disablePadding: true,
    label: "Question",
    width: "360px",
    render: (data) => <EnhancedTableTextCell value={data.questionText} />,
  },
  {
    id: "correctAnswer",
    numeric: false,
    disablePadding: true,
    label: "Correct Answer",
    width: "240px",
    render: (data) => <EnhancedTableTextCell value={data.correctAnswer} />,
  },
  {
    id: "count",
    numeric: true,
    disablePadding: false,
    label: "Count",
    width: "60px",
  },
];

export default function AdminReportsTable() {
  const navigate = useNavigate();

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const params = React.useMemo(
    () => ({ offset: page * rowsPerPage, limit: rowsPerPage }),
    [page, rowsPerPage]
  );
  const query = useGetApi("/admin/reports", params);
  const data = usePreviousFallback(
    query.status === "success" ? query.content : undefined
  );

  const { reports, total } = React.useMemo(() => {
    return {
      reports: data !== undefined ? (data.questions as Report[]) : [],
      total: data !== undefined ? (data.total as number) : 0,
    };
  }, [data]);

  return (
    <EnhancedTable
      title="Reports"
      headCells={headCells}
      rows={reports}
      rowCount={total}
      order="desc"
      setOrder={() => {}}
      orderBy={"count"}
      setOrderBy={() => {}}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      loading={query.status === "loading"}
    />
  );
}
