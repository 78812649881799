import { Button, ListItem, ListItemText } from "@mui/material";
import React from "react";
import useGetApi from "./useGetApi";
import { Edit as EditIcon } from "@mui/icons-material";
import PasswordChangeModal from "./PasswordChangeModal";

export default function SettingsPasswordListItem() {
  const [open, setOpen] = React.useState(false);
  const api = useGetApi("/settings/email");
  return (
    <>
      <ListItem
        secondaryAction={
          <Button
            color="inherit"
            startIcon={<EditIcon />}
            onClick={() => setOpen(true)}
          >
            Cambiar
          </Button>
        }
      >
        <ListItemText primary="Cambiar contraseña" />
      </ListItem>
      {open && (
        <PasswordChangeModal open={open} onClose={() => setOpen(false)} />
      )}
    </>
  );
}
