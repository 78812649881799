import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import useApi from "./useApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useSnackbar from "./useSnackbar";
import { passwordMaxLength, passwordMinLength } from "./constants";
import { LoadingButton } from "@mui/lab";
import { DialogContentText } from "@mui/material";
import useAnalytics from "./useAnalytics";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function PasswordChangeModal(props: Props) {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [showPasswordError, setShowPasswordError] = React.useState(false);
  const passwordError = React.useMemo(() => {
    if (newPassword.length < passwordMinLength) {
      return "Demasiado corta.";
    }
    if (newPassword.length > passwordMaxLength) {
      return "Demasiado larga.";
    }
  }, [newPassword]);
  const api = useApi();
  const snackbar = useSnackbar();
  const analytics = useAnalytics();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Cambiar contraseña</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Introduce tu contraseña actual y la nueva que quieres guardar.
        </DialogContentText>
        <TextField
          label="Contraseña actual"
          autoFocus
          margin="normal"
          type="password"
          fullWidth
          variant="outlined"
          value={currentPassword}
          autoComplete="current-password"
          onChange={(event: any) => setCurrentPassword(event.target.value)}
          inputProps={{
            maxLength: passwordMaxLength,
          }}
        />
        <TextField
          label="Contraseña nueva"
          margin="normal"
          type="password"
          fullWidth
          variant="outlined"
          value={newPassword}
          onChange={(event: any) => {
            setNewPassword(event.target.value);
            setShowPasswordError(true);
          }}
          autoComplete="new-password"
          inputProps={{ maxLength: passwordMaxLength }}
          error={showPasswordError && passwordError !== undefined}
          helperText={showPasswordError ? passwordError : undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancelar</Button>
        <LoadingButton
          variant="contained"
          disabled={passwordError !== undefined}
          loading={api.loading}
          onClick={
            api.loading
              ? undefined
              : () =>
                  api.post({
                    path: "/settings/password",
                    params: { currentPassword, newPassword },
                    onSuccess: () => {
                      props.onClose();
                      snackbar.success("Contraseña actualizada");
                      analytics.event("password_changed");
                    },
                    onError: (error) => {
                      if (error === "wrong password") {
                        snackbar.error("Contraseña actual incorrecta");
                        analytics.event("change_password_wrong_current");
                      } else {
                        analytics.event("change_password_error");
                      }
                    },
                  })
          }
        >
          Guardar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
