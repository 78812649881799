import React from "react";
import GameIntermissionGuest from "./GameIntermissionGuest";
import GameIntermissionHost from "./GameIntermissionHost";
import GameLink from "./GameLink";
import useSession from "./useSession";
import { Box } from "@mui/material";
import { ClassicState } from "./ClassicState";

interface Props {
  game: ClassicState;
}

export default function GameIntermission(props: Props) {
  const session = useSession();
  const host = React.useMemo(() => {
    const player = props.game.players.find(
      (player) => player.userId === session.user?.userId
    );
    return player !== undefined && player.host;
  }, [session.user?.userId, props.game.players]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <GameLink />
      {host ? <GameIntermissionHost /> : <GameIntermissionGuest />}
    </Box>
  );
}
