import {
  Box,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";

import { Logout as LogoutIcon } from "@mui/icons-material";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import { useNavigate } from "react-router-dom";
import useSession from "./useSession";
import useAnalytics from "./useAnalytics";

interface Props {
  onComplete: () => void;
}

export default function SignOutMenuItem(props: Props) {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const session = useSession();
  const api = useApi();
  const analytics = useAnalytics();
  return (
    <MenuItem
      disabled={api.loading}
      onClick={() => {
        if (api.loading) {
          return;
        }
        api.post({
          path: "/signout",
          params: {},
          onSuccess: () => {
            snackbar.success("Has cerrado sesión");
            props.onComplete();
            session.refresh();
            navigate("/");
            analytics.event("signout_success");
          },
          onError: () => {
            analytics.event("signout_error");
          },
        });
      }}
    >
      <ListItemIcon>
        <Box sx={{ position: "relative" }}>
          <LogoutIcon fontSize="small" />
          {api.loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </ListItemIcon>
      <ListItemText>Cerrar sesión</ListItemText>
    </MenuItem>
  );
}
