import React from "react";
import allCategories from "./categories";
import { Settings } from "./types";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

interface Props {
  initialSettings: Settings;
  onSave: (settings: Settings) => void;
  onCancel: () => void;
  submitButtonText: string;
}

export default function SettingsWidget(props: Props) {
  const { initialSettings, onSave, onCancel, submitButtonText } = props;
  const [title, setTitle] = React.useState(initialSettings.title);
  const [visibility, setVisibility] = React.useState(
    initialSettings.visibility
  );
  const [questionCount, setQuestionCount] = React.useState(
    initialSettings.questionCount
  );
  const [answerTime, setAnswerTime] = React.useState(
    initialSettings.answerTime
  );
  const [categories, setCategories] = React.useState(
    initialSettings.categories
  );

  function titleIsValid() {
    return title.trim().length > 0;
  }

  function categoriesIsValid() {
    return categories.length > 0;
  }

  function allIsValid() {
    return titleIsValid() && categoriesIsValid();
  }

  function getSettings() {
    return {
      title,
      visibility,
      questionCount,
      answerTime,
      categories,
    };
  }

  return (
    <Box sx={{ p: 0.5, flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Card
        sx={{
          p: 0.5,
          backgroundColor: "#e3dcd4",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Grid container direction="row">
          <Grid item xs={12} sm={4}>
            <Typography>Título</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              sx={{ backgroundColor: "white" }}
              size="small"
              fullWidth
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              error={!titleIsValid()}
              helperText={
                titleIsValid()
                  ? undefined
                  : "El título de la partida no puede ser vacío."
              }
              inputProps={{
                maxLength: 80,
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={4}>
            <Typography>Tipo de partida</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={visibility}
              onChange={(_, value) =>
                setVisibility(value === "public" ? "public" : "private")
              }
            >
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="Pública"
                sx={{ my: 0 }}
              />
              <FormControlLabel
                value="private"
                control={<Radio />}
                label="Privada"
                sx={{ my: 0 }}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={4}>
            <Typography>Preguntas</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              value={questionCount}
              onChange={(event) =>
                setQuestionCount(event.target.value as number)
              }
              sx={{ backgroundColor: "white" }}
              size="small"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={4}>
            <Typography>Tiempo de respuesta</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              value={answerTime}
              onChange={(event) => setAnswerTime(event.target.value as number)}
              sx={{ backgroundColor: "white" }}
              size="small"
            >
              <MenuItem value={5}>5 segundos</MenuItem>
              <MenuItem value={10}>10 segundos</MenuItem>
              <MenuItem value={15}>15 segundos</MenuItem>
              <MenuItem value={30}>30 segundos</MenuItem>
              <MenuItem value={45}>45 segundos</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={4}>
            <Typography>Categorías</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormGroup>
              {allCategories.map((category) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ py: 0 }}
                      checked={categories.includes(category.categoryId)}
                      onChange={(_, checked) => {
                        const newCategories = categories.filter(
                          (categoryId) => categoryId !== category.categoryId
                        );
                        if (checked) {
                          newCategories.push(category.categoryId);
                        }
                        setCategories(newCategories);
                      }}
                      name={category.name}
                    />
                  }
                  label={category.name}
                />
              ))}
            </FormGroup>
            {!categoriesIsValid() && (
              <FormHelperText sx={{ color: "red" }}>
                Debes seleccionar al menos una categoría.
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 2,
            mb: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onSave(getSettings());
            }}
            disabled={!allIsValid()}
          >
            Crear partida
          </Button>
          <Button variant="contained" color="inherit" onClick={onCancel}>
            Cancelar
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
