import React from "react";
import AnalyticsContext from "./AnalyticsContext";

interface Props {
  children: React.ReactNode;
}

export default function AnalyticsProvider(props: Props) {
  const analytics = React.useMemo(
    () => ({
      event: (name: string, parameters?: { [key: string]: string }) => {
        gtag("event", name, parameters);
      },
    }),
    [gtag]
  );
  return (
    <AnalyticsContext.Provider value={analytics}>
      {props.children}
    </AnalyticsContext.Provider>
  );
}
