import { Box, Button, Card, CircularProgress, Typography } from "@mui/joy";
import AdminLayout from "./AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import nullthrows from "nullthrows";
import useGetApi from "./useGetApi";
import { Contest } from "./AdminContestTypes";
import AdminContestEditForm from "./AdminContestEditForm";
import DeleteIcon from "@mui/icons-material/Delete";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";

export default function AdminContestEditPage() {
  const params = useParams();
  const contestId = nullthrows(params.contestId);
  const contestGet = useGetApi(`/admin/contests/${contestId}`);
  const contestDelete = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const handleDelete = () => {
    if (contestDelete.loading) {
      return;
    }
    contestDelete.delete({
      path: `/admin/contests/${contestId}`,
      params: {},
      onSuccess: () => {
        snackbar.success("Contest deleted");
        navigate(-1);
      },
    });
  };
  return (
    <AdminLayout>
      <Box sx={{ my: 2 }}>
        <Card>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ flexGrow: 1 }} level="h6" gutterBottom>
              Contest {contestId}
            </Typography>
            <Button
              startDecorator={<DeleteIcon />}
              onClick={handleDelete}
              color="danger"
              loading={contestDelete.loading}
            >
              Delete
            </Button>
          </Box>
          {contestGet.status === "loading" && <CircularProgress />}
          {contestGet.status === "success" && (
            <AdminContestEditForm
              contest={contestGet.content.contest as Contest}
            />
          )}
        </Card>
      </Box>
    </AdminLayout>
  );
}
