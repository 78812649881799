import React from "react";
import useSnackbar from "./useSnackbar";

const checkEveryMs = 500;

export default function OnlineStatusMonitor() {
  const snackbar = useSnackbar();
  const online = React.useRef<boolean>(true);
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (online.current && !navigator.onLine) {
        online.current = false;
        snackbar.stickyError("Se ha perdido la conexión a Internet.");
      } else if (!online.current && navigator.onLine) {
        online.current = true;
        snackbar.success("Se ha recuperado la conexión a Internet.");
      }
    }, checkEveryMs);
    return () => clearInterval(intervalId);
  }, [snackbar]);
  return <></>;
}
