import { Box } from "@mui/joy";
import AdminLayout from "./AdminLayout";
import AdminReportsTable from "./AdminReportsTable";

export default function AdminReportsPage() {
  return (
    <AdminLayout>
      <Box sx={{ my: 2 }}>
        <AdminReportsTable />
      </Box>
    </AdminLayout>
  );
}
