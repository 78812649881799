import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";
import { Card, Chip, Divider } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import logo from "./logo.png";
import {
  passwordMaxLength,
  passwordMinLength,
  usernameMaxLength,
  usernameMinLength,
} from "./constants";
import emailValidator from "email-validator";
import useGetApi from "./useGetApi";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import useSession from "./useSession";
import useTitle from "./useTitle";
import useAnalytics from "./useAnalytics";
import FullPageBackground from "./FullPageBackground";

export default function SignUpPage() {
  useTitle("Crea una cuenta");

  const [email, setEmail] = React.useState<string>("");
  const [showEmailError, setShowEmailError] = React.useState(false);
  const emailError = React.useMemo(() => {
    if (!emailValidator.validate(email)) {
      return "Correo electrónico inválido.";
    }
  }, [email]);
  const checkEmailParams = React.useMemo(() => ({ email }), [email]);
  const checkEmail = useGetApi(
    emailError === undefined ? "/signup/check-email" : undefined,
    checkEmailParams
  );

  const [password, setPassword] = React.useState<string>("");
  const [showPasswordError, setShowPasswordError] = React.useState(false);
  const passwordError = React.useMemo(() => {
    if (password.length < passwordMinLength) {
      return "Demasiado corta.";
    }
    if (password.length > passwordMaxLength) {
      return "Demasiado larga.";
    }
  }, [password]);

  const [username, setUsername] = React.useState<string>("");
  const [showUsernameError, setShowUsernameError] = React.useState(false);
  const usernameError = React.useMemo(() => {
    const regex = /^[a-zA-Z0-9._]*$/;
    if (!regex.test(username)) {
      return "Solo puede contener letras, números, punto y guion bajo.";
    }
    if (username.length < usernameMinLength) {
      return "Demasiado corto.";
    }
    if (username.length > usernameMaxLength) {
      return "Demasiado largo.";
    }
  }, [username]);
  const checkUsernameParams = React.useMemo(() => ({ username }), [username]);
  const checkUsername = useGetApi(
    usernameError === undefined ? "/signup/check-username" : undefined,
    checkUsernameParams
  );

  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const canSubmit =
    emailError === undefined &&
    checkEmail.status === "success" &&
    !checkEmail.content.registered &&
    passwordError === undefined &&
    usernameError === undefined &&
    checkUsername.status === "success" &&
    !checkUsername.content.registered &&
    acceptTerms;

  const submitApi = useApi();
  const session = useSession();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  return (
    <FullPageBackground>
      <Container component="main" maxWidth="xs">
        <Card
          sx={{
            marginTop: 8,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box component="img" sx={{ height: 80 }} src={logo} alt="Logo" />
          <Typography component="h1" variant="h5">
            Crea una cuenta
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Correo electrónico"
                  type="email"
                  autoComplete="email"
                  error={(() => {
                    if (!showEmailError) {
                      return false;
                    }
                    if (emailError !== undefined) {
                      return true;
                    }
                    if (
                      checkEmail.status === "success" &&
                      checkEmail.content.registered
                    ) {
                      return true;
                    }
                    if (checkEmail.status === "error") {
                      return true;
                    }
                    return false;
                  })()}
                  helperText={(() => {
                    if (!showEmailError) {
                      return undefined;
                    }
                    if (emailError !== undefined) {
                      return emailError;
                    }
                    if (checkEmail.status === "loading") {
                      return "Comprobando si está registrado...";
                    }
                    if (
                      checkEmail.status === "success" &&
                      checkEmail.content.registered
                    ) {
                      return "Ya existe una cuenta con este correo electrónico.";
                    }
                    if (checkEmail.status === "error") {
                      return "Error inesperado, inténtalo más tarde.";
                    }
                  })()}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setShowEmailError(true);
                  }}
                  inputProps={{ inputMode: "email" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Contraseña"
                  type="password"
                  autoComplete="new-password"
                  inputProps={{ maxLength: passwordMaxLength }}
                  error={showPasswordError && passwordError !== undefined}
                  helperText={showPasswordError ? passwordError : undefined}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setShowPasswordError(true);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Nombre de usuario"
                  autoComplete="nickname"
                  inputProps={{ maxLength: usernameMaxLength }}
                  error={(() => {
                    if (!showUsernameError) {
                      return false;
                    }
                    if (usernameError !== undefined) {
                      return true;
                    }
                    if (
                      checkUsername.status === "success" &&
                      checkUsername.content.registered
                    ) {
                      return true;
                    }
                    if (checkUsername.status === "error") {
                      return true;
                    }
                    return false;
                  })()}
                  helperText={(() => {
                    if (!showUsernameError) {
                      return undefined;
                    }
                    if (usernameError !== undefined) {
                      return usernameError;
                    }
                    if (checkUsername.status === "loading") {
                      return "Comprobando disponibilidad...";
                    }
                    if (
                      checkUsername.status === "success" &&
                      checkUsername.content.registered
                    ) {
                      return "Este nombre de usuario no está disponible.";
                    }
                    if (checkUsername.status === "error") {
                      return "Error inesperado, inténtalo más tarde.";
                    }
                  })()}
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                    setShowUsernameError(true);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acceptTerms}
                      onChange={(_event, checked) => {
                        setAcceptTerms(checked);
                      }}
                    />
                  }
                  label={
                    <>
                      Acepto los{" "}
                      <Link
                        component={RouterLink}
                        to="/terms-of-service"
                        target="_blank"
                      >
                        términos de servicio
                      </Link>
                      .
                    </>
                  }
                />
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              disabled={!canSubmit}
              loading={submitApi.loading}
              onClick={
                submitApi.loading
                  ? undefined
                  : () => {
                      submitApi.post({
                        path: "/signup",
                        params: { email, password, username },
                        onSuccess: (response) => {
                          session.refresh();
                          snackbar.success("Cuenta creada");
                          navigate("/verify");
                          analytics.event("signup_success");
                        },
                        onError: (error) => {
                          analytics.event("signup_error");
                        },
                      });
                    }
              }
            >
              Crear cuenta
            </LoadingButton>
            <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
              <Grid item>
                <Link component={RouterLink} to="/signin" variant="body2">
                  ¿Ya tienes una cuenta? Inicia sesión
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Copyright />
          </Box>
        </Card>
      </Container>
    </FullPageBackground>
  );
}
