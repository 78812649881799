import React from "react";

import { LinearProgress } from "@mui/material";

const updateEveryMs = 100;

interface Props {
  beginTime: number;
  endTime: number;
}

export default function ProgressBar(props: Props) {
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const totalTime = props.endTime - props.beginTime;
      const ratio = Math.max(
        0,
        Math.min(1, (now - props.beginTime) / totalTime)
      );
      setProgress(100 * ratio);
    }, updateEveryMs);
    return () => clearInterval(intervalId);
  }, [props.beginTime, props.endTime]);

  return (
    <LinearProgress
      sx={{ height: 18, borderRadius: 1 }}
      variant="determinate"
      value={progress}
    />
  );
}
