export default function fetchJson(params: {
  path: string;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}): () => void {
  const controller = new AbortController();
  fetch(`${process.env.REACT_APP_API_URL}${params.path}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    signal: controller.signal,
  })
    .then((response) => response.json())
    .then(params.onSuccess)
    .catch(params.onError);
  return () => {
    controller.abort();
  };
}
