import useClassicRoom from "./useClassicRoom";
import Page from "./Page";
import TopContainer from "./TopContainer";
import { RoomStatus } from "./createRoomContext";
import NotFoundPage from "./NotFoundPage";
import Loading from "./Loading";
import { Box, Grid } from "@mui/material";
import Scoreboard from "./Scoreboard";
import Chat from "./Chat";
import GameMain from "./GameMain";

export default function InnerGamePage() {
  const room = useClassicRoom();
  if (room.status === RoomStatus.notFound) {
    return <NotFoundPage />;
  }
  if (room.state === null) {
    return (
      <Page title="Cargando partida">
        <TopContainer>
          <Loading />
        </TopContainer>
      </Page>
    );
  }
  return (
    <Page title={room.state.title}>
      <TopContainer title={room.state.title}>
        <Grid container>
          <Grid item xs={12} md={7} lg={8}>
            <GameMain game={room.state} />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box sx={{ flexGrow: 1, p: 0.5 }}>
              <Scoreboard players={room.state.players.toArray()} />
            </Box>
            <Box sx={{ p: 0.5 }}>
              <Chat />
            </Box>
          </Grid>
        </Grid>
      </TopContainer>
    </Page>
  );
}
