import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import useTitle from "./useTitle";
import { Container } from "@mui/material";
import FullPageBackground from "./FullPageBackground";

interface Props {
  title: string;
  children: React.ReactNode;
}

function Page(props: Props) {
  useTitle(props.title);
  return (
    <FullPageBackground>
      <Header />
      <Container maxWidth="lg" sx={{ p: 0.5 }}>
        {props.children}
      </Container>
      <Footer />
    </FullPageBackground>
  );
}

export default Page;
