import React from "react";
import Cookies from "js-cookie";

import SessionContext, { AuthorizedUser } from "./SessionContext";
import fetchJson from "./fetchJson";

const waitOnSuccessMs = 60 * 1000; // 1 minute
const waitOnErrorMs = 1000; // 1 second

interface Props {
  children: React.ReactNode;
}

function getUser(cookie: string | null): AuthorizedUser | null {
  if (cookie === null) {
    return null;
  }
  let user;
  try {
    user = JSON.parse(cookie);
  } catch {
    return null;
  }
  const { userId, displayName, isGuest, emailVerified, isAdmin } = user;
  if (
    typeof userId === "string" &&
    typeof displayName === "string" &&
    typeof isGuest === "boolean" &&
    typeof emailVerified === "boolean"
  ) {
    return {
      userId,
      displayName,
      isGuest,
      emailVerified,
      isAdmin: typeof isAdmin === "boolean" ? isAdmin : false, // backwards compatible
    };
  }
  return null;
}

function getCookie(): string | null {
  const cookie = Cookies.get("user");
  if (cookie === undefined) {
    return null;
  }
  return cookie;
}

export default function SessionProvider(props: Props) {
  const initialCookie = React.useMemo(getCookie, []);
  const [cookie, setCookie] = React.useState(initialCookie);
  const initialUser = React.useMemo(
    () => getUser(initialCookie),
    [initialCookie]
  );
  const [user, setUser] = React.useState(initialUser);

  // Update user whenever the cookie changes.
  React.useEffect(() => {
    const user = getUser(cookie);
    setUser(user);
  }, [cookie, setUser]);

  // Refresh cookie.
  const refresh = React.useCallback(() => {
    const cookie = getCookie();
    setCookie(cookie);
  }, [getCookie, setUser]);

  // Fetch session periodically.
  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    let abort: (() => void) | null = null;
    const fetch = () => {
      abort = fetchJson({
        path: "/session",
        onSuccess: () => {
          timeoutId = setTimeout(fetch, waitOnSuccessMs);
          abort = null;
          refresh();
        },
        onError: () => {
          timeoutId = setTimeout(fetch, waitOnErrorMs);
          abort = null;
        },
      });
    };
    fetch();
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      if (abort !== null) {
        abort();
      }
    };
  }, [fetchJson, refresh]);

  const session = React.useMemo(
    () => ({
      user,
      refresh,
    }),
    [user, refresh]
  );
  return (
    <SessionContext.Provider value={session}>
      {props.children}
    </SessionContext.Provider>
  );
}
