import { useParams } from "react-router-dom";
import ClassicRoomProvider from "./ClassicRoomProvider";
import nullthrows from "nullthrows";
import InnerGamePage from "./InnerGamePage";

export default function GamePage() {
  const params = useParams();
  const gameId = nullthrows(params.gameId);
  return (
    <ClassicRoomProvider roomId={gameId}>
      <InnerGamePage />
    </ClassicRoomProvider>
  );
}
