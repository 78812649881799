import { Typography } from "@mui/joy";

interface Props {
  value: string;
}

export default function EnhancedTableDateCell(props: Props) {
  return (
    <Typography noWrap>{new Date(props.value).toLocaleString()}</Typography>
  );
}
