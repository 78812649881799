import { Button, IconButton, ListItem, ListItemText } from "@mui/material";
import { Done as DoneIcon } from "@mui/icons-material";
import React from "react";
import useGetApi from "./useGetApi";
import { useNavigate } from "react-router-dom";
import useSession from "./useSession";
import { Edit as EditIcon } from "@mui/icons-material";
import NameChangeModal from "./NameChangeModal";

export default function SettingsDisplayNameListItem() {
  const session = useSession();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <ListItem
        secondaryAction={
          <Button
            color="inherit"
            startIcon={<EditIcon />}
            onClick={() => setOpen(true)}
          >
            Cambiar
          </Button>
        }
      >
        <ListItemText
          primary="Nombre visible"
          secondary={session.user?.displayName}
        />
      </ListItem>
      {open && <NameChangeModal open={open} onClose={() => setOpen(false)} />}
    </>
  );
}
