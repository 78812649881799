import { ClassicState } from "./ClassicState";
import createRoomContext from "./createRoomContext";

import type { Room } from "./createRoomContext";

export type ClassicRoom = Room<ClassicState>;

const ClassicRoomContext = createRoomContext<ClassicState>();

export default ClassicRoomContext;
