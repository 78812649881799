import EnhancedTable from "./EnhancedTable";
import { HeadCell } from "./EnhancedTableHead";
import React from "react";
import useGetApi from "./useGetApi";
import EnhancedTableBooleanCell from "./EnhancedTableBooleanCell";
import EnhancedTableDateCell from "./EnhancedTableDateCell";
import usePreviousFallback from "./usePreviousFallback";

interface User {
  _id: string;
  username: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  isAdmin: boolean;
  createdAt: string;
}

const headCells: readonly HeadCell<User>[] = [
  {
    id: "_id",
    numeric: false,
    disablePadding: true,
    label: "User ID",
    width: "240px",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
    width: "180px",
  },
  {
    id: "displayName",
    numeric: false,
    disablePadding: false,
    label: "Display Name",
    width: "180px",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    width: "240px",
  },
  {
    id: "emailVerified",
    numeric: false,
    disablePadding: false,
    label: "Verified",
    render: (data) => <EnhancedTableBooleanCell value={data.emailVerified} />,
    width: "60px",
  },
  {
    id: "isAdmin",
    numeric: false,
    disablePadding: false,
    label: "Admin",
    render: (data) => <EnhancedTableBooleanCell value={data.isAdmin} />,
    width: "60px",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    render: (data) => <EnhancedTableDateCell value={data.createdAt} />,
    width: "180px",
  },
];

export default function AdminUsersTable() {
  const [order, setOrder] = React.useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = React.useState<keyof User>("createdAt");
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  const params = React.useMemo(
    () => ({ offset: page * rowsPerPage, limit: rowsPerPage, order, orderBy }),
    [page, rowsPerPage, order, orderBy]
  );
  const query = useGetApi("/admin/users", params);
  const data = usePreviousFallback(
    query.status === "success" ? query.content : undefined
  );

  const { users, total } = React.useMemo(() => {
    return {
      users: data !== undefined ? (data.users as User[]) : [],
      total: data !== undefined ? (data.total as number) : 0,
    };
  }, [data]);

  return (
    <EnhancedTable
      title="Users"
      headCells={headCells}
      rows={users}
      rowCount={total}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      loading={query.status === "loading"}
    />
  );
}
