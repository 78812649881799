import { Box } from "@mui/material";
import Question from "./Question";
import QuestionHeader from "./QuestionHeader";
import { ClassicState } from "./ClassicState";

interface Props {
  game: ClassicState;
}

export default function GameQuestion(props: Props) {
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Box sx={{ p: 0.5 }}>
        <QuestionHeader
          category={props.game.question.category}
          questionIndex={props.game.questionIndex}
          questionCount={props.game.questionCount}
          inProgress={props.game.status === "questionInProgress"}
          beginTime={props.game.question.beginTime}
          endTime={props.game.question.beginTime + 1000 * props.game.answerTime}
        />
      </Box>
      <Box
        sx={{ p: 0.5, flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <Question
          question={props.game.question}
          inProgress={props.game.status === "questionInProgress"}
        />
      </Box>
    </Box>
  );
}
