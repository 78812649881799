import { Box } from "@mui/joy";
import AdminLayout from "./AdminLayout";
import AdminContestsTable from "./AdminContestsTable";

export default function AdminContestsPage() {
  return (
    <AdminLayout>
      <Box sx={{ my: 2 }}>
        <AdminContestsTable />
      </Box>
    </AdminLayout>
  );
}
