import { deepOrange } from "@mui/material/colors";
import useSession from "./useSession";
import { Avatar as MuiAvatar } from "@mui/material";

export default function Avatar() {
  const session = useSession();
  const isGuest = session.user?.isGuest !== false;
  if (isGuest) {
    return <MuiAvatar alt={session.user?.displayName} />;
  }
  return (
    <MuiAvatar
      alt={session.user?.displayName}
      sx={{ bgcolor: deepOrange[500] }}
    >
      {session.user?.displayName?.[0]}
    </MuiAvatar>
  );
}
