import { ListItemButton } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  path: string;
  children: React.ReactNode;
}

export default function AdminSidebarListItemButton(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const selected = location.pathname === props.path;
  return (
    <ListItemButton
      onClick={() => navigate(props.path)}
      selected={selected}
      variant={selected ? "soft" : "plain"}
    >
      {props.children}
    </ListItemButton>
  );
}
