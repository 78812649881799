import React from "react";
import { Link } from "react-router-dom";
import Page from "./Page";
import { Card, Typography } from "@mui/material";
import {
  Person as PersonIcon,
  Star as StarIcon,
  Login as LoginIcon,
} from "@mui/icons-material";

function FaqPage() {
  return (
    <Page title="Preguntas frecuentes">
      <Card sx={{ p: 3 }}>
        <Typography variant="h3" gutterBottom>
          Preguntas frecuentes
        </Typography>
        <Typography variant="h4" gutterBottom>
          ¿Cuántos puntos gano por cada respuesta correcta?
        </Typography>
        <Typography gutterBottom>
          Cada vez que respondas correctamente a una pregunta ganarás entre 100
          y 200 puntos, dependiendo de la rapidez con la que contestes.
        </Typography>
        <Typography variant="h4" gutterBottom>
          ¿Qué significan los iconos que aparecen en el ranking?
        </Typography>
        <Typography gutterBottom>
          Los siguientes iconos suelen aparecer al lado del nombre de cada
          jugador.
        </Typography>
        <ul>
          <li>
            <Typography>
              <PersonIcon /> Indica tu propio jugador. Siempre aparece al lado
              de tu nombre.
            </Typography>
          </li>
          <li>
            <Typography>
              <StarIcon /> El anfitrión del partida. Es quien controla los
              ajustes de la partida.
            </Typography>
          </li>
          <li>
            <Typography>
              <LoginIcon /> El jugador entró a media partida. Es probable que es
              jugador no tuviera la oportunidad de acumular tantos puntos como
              los demás.
            </Typography>
          </li>
        </ul>
        <Typography variant="h4" gutterBottom>
          Mi pregunta no está resuelta aquí
        </Typography>
        <Typography gutterBottom>
          En este caso, <Link to="/contact.html">contacta con nosotros</Link> y
          trataremos de ayudarte.
        </Typography>
      </Card>
    </Page>
  );
}

export default FaqPage;
