import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import useTitle from "./useTitle";
import emailValidator from "email-validator";
import useGetApi from "./useGetApi";
import useAnalytics from "./useAnalytics";

interface Props {
  onComplete: (email: string) => void;
}

export default function RecoverPageEmail(props: Props) {
  useTitle("Recupera tu cuenta");

  const snackbar = useSnackbar();
  const analytics = useAnalytics();

  // Email prompt
  const [email, setEmail] = React.useState<string>("");
  const [showEmailError, setShowEmailError] = React.useState(false);
  const emailError = React.useMemo(() => {
    if (!emailValidator.validate(email)) {
      return "Correo electrónico inválido.";
    }
  }, [email]);
  const checkEmailParams = React.useMemo(() => ({ email }), [email]);
  const checkEmailApi = useGetApi(
    emailError === undefined ? "/signup/check-email" : undefined,
    checkEmailParams
  );

  // Form validation
  const canSubmit = emailError === undefined;

  // Submit
  const generateTokenApi = useApi();

  return (
    <>
      <Typography component="p" variant="body1">
        Introduce tu correo electrónico a continuación y te enviaremos un código
        de verificación para recuperar tu cuenta.
      </Typography>
      <Box sx={{ mt: 3, alignSelf: "stretch" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Correo electrónico"
              type="email"
              autoComplete="email"
              error={(() => {
                if (!showEmailError) {
                  return false;
                }
                if (emailError !== undefined) {
                  return true;
                }
                if (
                  checkEmailApi.status === "success" &&
                  !checkEmailApi.content.registered
                ) {
                  return true;
                }
                if (checkEmailApi.status === "error") {
                  return true;
                }
                return false;
              })()}
              helperText={(() => {
                if (!showEmailError) {
                  return undefined;
                }
                if (emailError !== undefined) {
                  return emailError;
                }
                if (checkEmailApi.status === "loading") {
                  return "Comprobando si está registrado...";
                }
                if (
                  checkEmailApi.status === "success" &&
                  !checkEmailApi.content.registered
                ) {
                  return "No existe ninguna cuenta con este correo electrónico.";
                }
                if (checkEmailApi.status === "error") {
                  return "Error inesperado, inténtalo más tarde.";
                }
              })()}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setShowEmailError(true);
              }}
              inputProps={{
                inputMode: "email",
              }}
            />
          </Grid>
        </Grid>
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          disabled={!canSubmit}
          loading={generateTokenApi.loading}
          onClick={
            generateTokenApi.loading
              ? undefined
              : () => {
                  generateTokenApi.post({
                    path: "/recover/generate-token",
                    params: { email },
                    onSuccess: (response) => {
                      snackbar.success("Código de verificación enviado");
                      props.onComplete(email);
                      analytics.event("recover_email_sent");
                    },
                    onError: (error) => {
                      analytics.event("recover_email_error");
                    },
                  });
                }
          }
        >
          Continuar
        </LoadingButton>
      </Box>
    </>
  );
}
