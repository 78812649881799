import nullthrows from "nullthrows";
import React from "react";
import SettingsWidget from "./SettingsWidget";
import { Settings, Visibility } from "./types";
import useAnalytics from "./useAnalytics";
import useClassicRoom from "./useClassicRoom";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import {
  Replay as ReplayIcon,
  Build as BuildIcon,
  MeetingRoom as MeetingRoomIcon,
} from "@mui/icons-material";

function validateVisibility(visibility: string): Visibility {
  if (visibility === "public" || visibility === "private") {
    return visibility;
  }
  throw new Error("invalid visibility");
}

export default function GameIntermissionHost() {
  const room = useClassicRoom();
  const [settingsMode, setSettingsMode] = React.useState(false);
  const initialSettings = React.useMemo<Settings>(() => {
    const state = nullthrows(room.state);
    return {
      title: state.title,
      visibility: validateVisibility(state.visibility),
      questionCount: state.questionCount,
      answerTime: state.answerTime,
      categories: state.categories.toArray(),
    };
  }, [room.state]);
  const analytics = useAnalytics();

  if (settingsMode) {
    return (
      <SettingsWidget
        initialSettings={initialSettings}
        onSave={(settings) => {
          room.send("settings", settings);
          analytics.event("settings_updated");
          setSettingsMode(false);
        }}
        onCancel={() => {
          setSettingsMode(false);
        }}
        submitButtonText="Guardar cambios"
      />
    );
  }

  return (
    <Box sx={{ p: 0.5, flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Card sx={{ backgroundColor: "#e3dcd4", py: 5, px: 1, flexGrow: 1 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Fin de la partida
        </Typography>
        <Grid container direction="column" spacing={2} sx={{ py: 3 }}>
          <Grid item container direction="row" justifyContent="center">
            <Grid item xs={12} sm={7} lg={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  room.send("start");
                  analytics.event("game_restarted");
                }}
                startIcon={<ReplayIcon />}
              >
                ¡Jugar de nuevo!
              </Button>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="center">
            <Grid item xs={12} sm={7} lg={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  setSettingsMode(true);
                }}
                startIcon={<BuildIcon />}
                color="inherit"
              >
                Ajustes
              </Button>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="center">
            <Grid item xs={12} sm={7} lg={6}>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  room.send("finish");
                  analytics.event("game_finished");
                }}
                startIcon={<MeetingRoomIcon />}
                color="error"
              >
                Abandonar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Typography gutterBottom>
          Puedes cambiar los ajustes antes de empezar la nueva partida.
        </Typography>
        <Typography gutterBottom>
          Si juegas con más jugadores, no les hagas esperar demasiado, o se
          cansarán y se irán.
        </Typography>
      </Card>
    </Box>
  );
}
