import Copyright from "./Copyright";
import { Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Facebook as FacebookIcon } from "@mui/icons-material";

export default function Footer() {
  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          pt: 2,
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <Link
          component={RouterLink}
          to="/contact"
          sx={{
            color: "white",
          }}
        >
          Reportar un problema
        </Link>
        <Link
          component={RouterLink}
          to="/faq"
          sx={{
            color: "white",
          }}
        >
          Preguntas frecuentes
        </Link>
        <Link
          component={RouterLink}
          to="/privacy-policy"
          sx={{
            color: "white",
          }}
        >
          Política de privacidad
        </Link>
        <Link
          component={RouterLink}
          to="/terms-of-service"
          sx={{
            color: "white",
          }}
        >
          Términos de servicio
        </Link>
      </Box>

      <Box
        sx={{
          textAlign: "center",
          color: "white",
          pt: 2,
          display: "flex",
          gap: 2,
          justifyContent: "center",
        }}
      >
        <Box>
          <Copyright />
        </Box>
        <Box>
          <Link
            href="https://www.facebook.com/TrivialOnlinees-423873727655602/"
            target="_blank"
            rel="noopener"
            sx={{
              color: "white",
            }}
          >
            <FacebookIcon />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
