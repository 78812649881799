import { ClassicState } from "./ClassicState";
import GameFinished from "./GameFinished";
import GameIntermission from "./GameIntermission";
import GamePrepare from "./GamePrepare";
import GameQuestion from "./GameQuestion";

interface Props {
  game: ClassicState;
}

export default function GameMain(props: Props) {
  if (props.game.status === "prepare") {
    return <GamePrepare game={props.game} />;
  }
  if (
    props.game.status === "questionInProgress" ||
    props.game.status === "questionEnd"
  ) {
    return <GameQuestion game={props.game} />;
  }
  if (props.game.status === "intermission") {
    return <GameIntermission game={props.game} />;
  }
  if (props.game.status === "finished") {
    return <GameFinished />;
  }
  return null;
}
