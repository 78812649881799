import { Alert, AlertTitle, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Page from "./Page";

export default function NotFoundPage() {
  return (
    <Page title="404 Página no encontrada">
      <Alert severity="error">
        <AlertTitle>404 Página no encontrada</AlertTitle>
        <p>
          La página a la que intentas acceder no existe. Revisa que el enlace
          sea correcto.
        </p>
        <p>
          Vuelve a la{" "}
          <Link component={RouterLink} to="/">
            lista de partidas
          </Link>{" "}
          o{" "}
          <Link component={RouterLink} to="/game">
            crea una partida
          </Link>
          .
        </p>
      </Alert>
    </Page>
  );
}
