import React from "react";
import Clipboard from "clipboard";
import nullthrows from "nullthrows";
import {
  Box,
  Card,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import {
  Link as LinkIcon,
  ContentPaste as ContentPasteIcon,
} from "@mui/icons-material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

export default function GameLink() {
  const [status, setStatus] = React.useState<"none" | "success" | "error">(
    "none"
  );
  const button = React.useRef<HTMLButtonElement>(null);
  const clipboard = React.useRef<Clipboard>();

  React.useEffect(() => {
    clipboard.current = new Clipboard(nullthrows(button.current));
    clipboard.current.on("success", () => setStatus("success"));
    clipboard.current.on("error", () => setStatus("error"));
  }, []);

  return (
    <Box sx={{ p: 0.5 }}>
      <Card sx={{ backgroundColor: "#cce5ff", color: "#004085", p: 1 }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box>
            <LinkIcon sx={{ fontSize: 90 }} />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography>
              <strong>¿Quieres jugar con tus amigos?</strong>
            </Typography>
            <Typography>¡Comparte con ellos el enlace a la partida!</Typography>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ "aria-label": "shareable link" }}
                value={window.location.href}
                readOnly={true}
              />
              <IconButton
                color={(() => {
                  if (status === "none") return "primary";
                  if (status === "success") return "success";
                  return "error";
                })()}
                aria-label="copy"
                title="Copiar"
                data-clipboard-text={window.location.href}
                ref={button}
              >
                {status === "success" ? (
                  <AssignmentTurnedInIcon />
                ) : (
                  <ContentPasteIcon />
                )}
              </IconButton>
            </Paper>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
