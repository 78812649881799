import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useApi from "./useApi";
import useSnackbar from "./useSnackbar";
import useTitle from "./useTitle";
import emailValidator from "email-validator";
import useGetApi from "./useGetApi";
import { useNavigate } from "react-router-dom";
import useSession from "./useSession";
import { passwordMaxLength, passwordMinLength } from "./constants";
import useAnalytics from "./useAnalytics";

interface Props {
  email: string;
  token: string;
}

export default function RecoverPagePassword(props: Props) {
  useTitle("Recupera tu cuenta");

  const session = useSession();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  // Password prompt
  const [password, setPassword] = React.useState<string>("");
  const [showPasswordError, setShowPasswordError] = React.useState(false);
  const passwordError = React.useMemo(() => {
    if (password.length < passwordMinLength) {
      return "Demasiado corta.";
    }
    if (password.length > passwordMaxLength) {
      return "Demasiado larga.";
    }
  }, [password]);

  // Form validation
  const canSubmit = passwordError === undefined;

  // Submit
  const setPasswordApi = useApi();

  return (
    <>
      <Typography component="p" variant="body1">
        Introduce una contraseña nueva para recuperar tu cuenta.
      </Typography>
      <Box sx={{ mt: 3, alignSelf: "stretch" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Contraseña"
              type="password"
              autoComplete="new-password"
              inputProps={{ maxLength: passwordMaxLength }}
              error={showPasswordError && passwordError !== undefined}
              helperText={showPasswordError ? passwordError : undefined}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
                setShowPasswordError(true);
              }}
            />
          </Grid>
        </Grid>
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          disabled={!canSubmit}
          loading={setPasswordApi.loading}
          onClick={
            setPasswordApi.loading
              ? undefined
              : () => {
                  setPasswordApi.post({
                    path: "/recover/set-password",
                    params: {
                      email: props.email,
                      token: props.token,
                      password,
                    },
                    onSuccess: (response) => {
                      snackbar.success("Nueva contraseña guardada con éxito");
                      session.refresh();
                      navigate("/");
                      analytics.event("recover_password_success");
                    },
                    onError: (error) => {
                      analytics.event("recover_password_error");
                    },
                  });
                }
          }
        >
          Guardar contraseña
        </LoadingButton>
      </Box>
    </>
  );
}
