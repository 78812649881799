import React from "react";
import * as Colyseus from "colyseus.js";
import ColyseusContext from "./ColyseusContext";

interface Props {
  address: string;
  children: React.ReactNode;
}

export default function ColyseusProvider(props: Props) {
  const client = React.useMemo(
    () => new Colyseus.Client(props.address),
    [props.address]
  );
  return (
    <ColyseusContext.Provider value={client}>
      {props.children}
    </ColyseusContext.Provider>
  );
}
